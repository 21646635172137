const aboutMd = `
[[English](#english-version) | [Dansk](#danish-version)]

_A little about Maria Guttesen._ <a name="english-version"></a>

Maria Guttesen was born and raised in Torshavn in the Faroe Islands, where music has been a
natural part of her life since she was very young. At the age of 20, she moved to Aarhus when she
heard that it was the city of music. After a number of years as a singer and musician in Aarhus, she
graduated from the Royal Academy of Music in 2001.

She was one of the first Faroese female singers, and later multi-instrumentalist, composer and
producer, who was completely her own and a more powerful performer than had previously been
seen on the Faroese and Danish live scene. Her debut album "Where It All Begins" was released in
1994 by the Faroese record company Tutl.

Maria now has a long career as a performing singer, musician, composer, talent developer, music
publisher, arranger, music producer, and project manager. She has played a multitude of gigs both at
home and abroad, and for the past 20 years has had a strong collaboration with Danish Lene
Drachmann, who has written the lyrics and created artwork.

Maria Guttesen has further released albums of her own material both under her own name, and in
collaboration with, among others, pianist Stephen Nørrelykke under the name Divided Minds
and guitarist Peter Steenild under the name STEENILD+GUTTESEN.

She has always followed her musical heart and has therefore stuck to her natural expression and
empathy, she has been artistically uncompromising with her own productions regardless of the
conditions and consequences. She is still a singer, composer and musician who is hard to place.

As a musical artist, Maria is a woman who has not let the dominance of men in the music business
slow her down. She is just comfortable in the colorful universe of music, therefore she has always
been in musical motion in many forms both as an artist and talent developer.

Her passion has always been to develop talent, initiate and use of her musicality and share her
experiences. Therefore, she has been associated with Holstebro Music School and Klejtrup Music
School as a singing and music teacher, and has been a mentor for many young musicians.

Maria Guttesen has received many accolades and stars from the critics, including the releases "Wide
Open" and "Live", Divided Minds "The Last Night" and for STEENILD+GUTTESEN "Reconstructed".

In 2008 Maria lived her dream by recording the album "Wide Open". She sang and played all
instruments, was composer and producer. Since then she has been multi-instrumentalist, composer
and producer on several releases.

<hr />

_Lidt om Maria Guttesen._ <a name="danish-version"></a>

Maria Guttesen er født og opvokset i Torshavn på Færøerne, hvor musik har været en naturlig del af
hendes liv siden hun var helt lille. Som 20-årig flyttede hun til Aarhus, da hun hørte at det var
musikkens by. Efter en del år som sanger og musiker i Aarhus, blev hun i 2001 færdiguddannet på
Det Jyske Musikkonservatorium.

Hun var en af de første færøske kvindelige sangere, og senere multi-instrumentalist, komponist og
producer, som var helt sin egen og en mere powerfuld performer end man tidligere havde set på den
færøske og danske live scene. Hendes debutalbum ”Where It All Begins” blev udgivet i 1994 af det
færøske pladeselskab Tutl.

Maria har nu en lang karriere som udøvende sanger, musiker, komponist, talentudvikler,
musikudgiver, arrangør, musikproducer, og projektmager. Hun har spillet et hav af koncerter i både
ind- og udland, og har de sidste 20 år haft et stærkt samarbejde med danske Lene Drachmann, som
har skrevet sangtekster og lavet artwork.

Maria Guttesen har yderligere udgivet albums med eget materiale både under eget navn, og i
samarbejde med blandt andre pianisten Stephen Nørrelykke under navnet Divided Minds og
guitaristen Peter Steenild under navnet STEENILD+GUTTESEN.

Hun har altid fulgt sit musiske hjerte og har derfor holdt fast i sit naturlige udtryk og sin indlevelse.
Hun har været kunstnerisk kompromisløs med egne produktioner uanset vilkårene og følgerne. Hun
er stadig en sanger, komponist og musiker som er svær at placere.

Maria er som musisk kunstner, en kvinde, som ikke har ladet sig bremse af mændenes dominans i
musikbranchen. Hun befinder sig bare godt i musikkens farverige univers, derfor har hun hele tiden
været i musikalsk bevægelse i mange afskygninger både som kunstner og talentudvikler.

Hendes passion har altid været at talentudvikle, igangsætte og bruge af sin musikalitet og dele ud af
sine erfaringer. Derfor har hun de sidste mange år også været tilknyttet Holstebro Musikskole og
Klejtrup Musikefterskole som sang- og musikunderviser, og været mentor for mange unge musikere.

Maria Guttesen har høstet mange roser og stjerner fra anmelderne, blandt andet udgivelserne
”Wide Open” og ”Live”, Divided Minds “The Last Night” og for STEENILD+GUTTESEN “Reconstructed”.

I 2008 udlevede Maria sin drøm ved at indspillede albummet ”Wide Open”. Hun sang og spillede
samtlige instrumenter, var komponist og producer. Sidenhen har hun været multi–instrumentalist,
komponist og producer på flere udgivelser.`;

export default aboutMd;
