import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { data } from "../data.js";
import Project from "../components/Project";
import ProjectList from "../components/ProjectList";
import Song from "../components/Song";
import VerticalSpace from "../components/VerticalSpace";
import withScrollToTopWhenMounted from "../components/withScrollToTopWhenMounted";

const findProjectById = (id) => {
  const matches = data.projects.filter((project) => project.id === id);
  if (matches.length > 0) return matches[0];
  else return null;
};

const StyledListItem = styled.li`
  color: #888;
  font-size: 20px;
  padding-left: 10px;
`;

const Heading = styled.h2`
  margin: 0;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 25px;
`;

const SongList = ({ songs }) => (
  <ol>
    {songs.map((song) => (
      <StyledListItem key={song.id}>
        <Song metadata={song} />
      </StyledListItem>
    ))}
  </ol>
);

const ProjectView = () => {
  const { projectId } = useParams();
  const projectData = findProjectById(projectId);

  if (projectData === null) {
    return <p>Could not find project with ID "{projectId}"</p>;
  }

  return (
    <React.Fragment>
      <ProjectList>
        <Project metadata={projectData} />
      </ProjectList>
      <VerticalSpace $amount={50} />
      <Heading>Songs</Heading>
      <SongList songs={projectData.songs} />
    </React.Fragment>
  );
};

export default withScrollToTopWhenMounted(ProjectView);
