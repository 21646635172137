import React, { Component } from "react";

const withScrollToTopWhenMounted = (WrappedComponent) => {
    return class extends Component {
        componentDidMount() {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    }
};

export default withScrollToTopWhenMounted;
