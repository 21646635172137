import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Role = styled.span`
  margin-right: 5px;
  margin-top: 5px;
  padding: 3px 5px;
  font-size: 12px;
  border: 1px solid #ddd;
  color: #0366d6;
  cursor: pointer;

  &:after {
    content: "${({ $projectRole }) => $projectRole}";
  }

  transition: all 300ms ease;

  &:hover {
    border-color: #0366d6;
  }

  ${({ $highlight, theme }) =>
    $highlight &&
    `
        color: ${theme.highlight};
        
        &:hover {
            border-color: ${theme.highlight};
        }
    `}
`;

const RolesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Roles = ({ roles, highlightRole = "" }) => {
  const navigate = useNavigate();

  return (
    <RolesContainer>
      {roles.map((role) => (
        <Role
          key={Date.now() + role}
          onClick={() =>
            highlightRole === role ? navigate(`/`) : navigate(`/role/${role}`)
          }
          $projectRole={role}
          $highlight={highlightRole === role}
        />
      ))}
    </RolesContainer>
  );
};

export default Roles;
