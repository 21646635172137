import React from "react";
import styled from "styled-components";
import { Routes, Route, useNavigate } from "react-router-dom";

import RoleView from "./views/RoleView";
import AboutView from "./views/AboutView";
import VideosView from "./views/VideosView";
import TypeView from "./views/TypeView";
import ProjectView from "./views/ProjectView";
import LyricsView from "./views/LyricsView";
import OverviewView from "./views/OverviewView";
import VerticalSpace from "./components/VerticalSpace";
import Navbar from "./components/Navbar";

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Page = styled.div`
  padding: 60px;

  @media (max-width: 1200px) {
    padding: 30px;
  }

  @media (max-width: 650px) {
    padding: 30px 20px;
  }
`;

const Hero = styled(Page)`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 2px 1px 10px 1px;
  border-bottom: 1px solid #ddd;

  & > * ~ * {
    margin: 50px 0 0 0;
  }

  @media (max-width: 650px) {
    align-items: center;
    & > * ~ * {
      margin: 30px 0 0 0;
    }
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  & > * ~ * {
    margin: 0 0 0 50px;
  }

  @media (max-width: 650px) {
    flex-direction: column;
    & > * ~ * {
      margin: 20px 0 0 0;
    }
  }
`;

const ProfileImage = styled.img`
  display: block;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.15) 2px 1px 10px 1px;
  border: 1px solid #ddd;

  @media (max-width: 650px) {
    width: 100px;
    height: 100px;
  }
`;

const PageTitle = styled.h1`
  display: inline-flex;
  align-items: center;
  height: 70px;
  font-size: 30px;
  font-family: "Muli", sans-serif;
  text-transform: uppercase;
  padding: 0 15px;
  cursor: pointer;
  box-sizing: border-box;
  transition: font-size 300ms ease, background-color 300ms ease;

  background-color: white;
  color: ${({ theme }) => theme.primary};

  &:hover {
    background-color: ${({ theme }) => theme.primary};
    border-color: transparent;
    color: #fff;

    span {
      background-color: white;
      border-color: #ddd;
      color: ${({ theme }) => theme.primary};
    }
  }

  span {
    font-family: "Permanent Marker";
    font-weight: normal;
    display: flex;
    align-items: center;
    padding: 0 15px;
    margin-left: 15px;
    font-size: 40px;
    height: 90px;
    transition: font-size 300ms ease, background-color 300ms ease;
    border: 1px solid;

    background-color: ${({ theme }) => theme.primary};
    border-color: transparent;
    color: white;
  }

  @media (max-width: 800px) {
    font-size: 20px;
    height: 40px;
    padding: 0 10px;

    span {
      height: 50px;
      font-size: 30px;
      padding: 0 10px;
      margin-left: 10px;
    }
  }

  @media (max-width: 375px) {
    font-size: 16px;

    span {
      font-size: 24px;
    }
  }
`;

const App = () => {
  const navigate = useNavigate();

  const handlePageTitleClick = () => {
    navigate("/");
  };

  return (
    <AppContainer>
      <Hero>
        <Row>
          <ProfileImage alt="Maria Guttesen" src="/mariaguttesen.jpg" />
          <PageTitle onClick={handlePageTitleClick}>
            Maria Guttesen <span>Music</span>
          </PageTitle>
        </Row>
        <Navbar />
      </Hero>
      <Page>
        <Routes>
          <Route exact path="/about" element={<AboutView />} />
          <Route exact path="/videos" element={<VideosView />} />
          <Route exact path="/role/:roleName" element={<RoleView />} />
          <Route exact path="/type/:typeName" element={<TypeView />} />
          <Route exact path="/project/:projectId" element={<ProjectView />} />
          <Route exact path="/song/:songId/lyrics" element={<LyricsView />} />
          <Route exact path="/" element={<OverviewView />} />
        </Routes>
      </Page>
      <VerticalSpace $amount={100} />
    </AppContainer>
  );
};

export default App;
