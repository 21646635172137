import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Roles from "./Roles";
import HorizontalSpace from "./HorizontalSpace";
import SubtleMetadata from "./SubtleMetadata";

const ProjectTitle = styled.span`
  font-size: 16px;
  font-weight: normal;
  margin: 0;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const ProjectContainer = styled.div`
  display: flex;
  margin: 10px 0;
`;

const MetadataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

const Cover = styled.div`
  border: 1px solid #ddd;
  width: 70px;
  min-width: 70px;
  max-width: 70px;
  height: 70px;
  min-height: 70px;
  max-height: 70px;
  margin: 0 5px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
`;

const Type = styled.span`
  padding: 1px 4px;
  text-transform: uppercase;
  font-size: 9px;
  border: 1px solid #ddd;
  color: #000;
  border-radius: 5px;
  cursor: pointer;
  transition: all 300ms ease;

  &:hover {
    border-color: #000;
  }

  ${({ $highlight, theme }) =>
    $highlight &&
    `
        color: ${theme.highlight};
        
        &:hover {
            border-color: ${theme.highlight};
        }
    `}
`;

const MetadataGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const CoverImage = styled.img`
  width: 100%;
  height: 100%;
`;

const IconImage = styled.img`
  width: auto;
  height: 100%;
`;

const LinkToAlbum = styled.a.attrs(({ href }) => ({
  href,
  target: "_blank",
  rel: "noreferrer",
}))`
  display: flex;
  height: 14px;
  &:hover {
    opacity: 0.6;
  }
`;

const SpotifyLink = ({ song }) => (
  <LinkToAlbum href={song.listenOn.spotify}>
    <IconImage alt="spotify-icon" src={"/images/spotify-icon.png"} />
  </LinkToAlbum>
);

const YouTubeLink = ({ song }) => (
  <LinkToAlbum href={song.listenOn.youtube}>
    <IconImage alt="youtube-icon" src={"/images/youtube-icon.png"} />
  </LinkToAlbum>
);

const AppleMusicLink = ({ song }) => (
  <LinkToAlbum href={song.listenOn.appleMusic}>
    <IconImage alt="apple-music-icon" src={"/images/apple-music-icon.svg"} />
  </LinkToAlbum>
);

const SoundCloudLink = ({ song }) => (
  <LinkToAlbum href={song.listenOn.soundcloud}>
    <IconImage alt="soundcloud-icon" src={"/images/soundcloud-icon.png"} />
  </LinkToAlbum>
);

const Project = ({ metadata, highlightRole = "", highlightType = "" }) => {
  const navigate = useNavigate();

  return (
    <ProjectContainer>
      <Cover onClick={() => navigate(`/project/${metadata.id}`)}>
        <CoverImage alt={metadata.title} src={`/images${metadata.img}`} />
      </Cover>
      <MetadataContainer>
        <MetadataGroup>
          <ProjectTitle onClick={() => navigate(`/project/${metadata.id}`)}>
            {metadata.title}
          </ProjectTitle>
          <HorizontalSpace $amount={5} />
          <Type
            onClick={() =>
              highlightType === metadata.type
                ? navigate(`/`)
                : navigate(`/type/${metadata.type}`)
            }
            $highlight={highlightType === metadata.type}
          >
            {metadata.type}
          </Type>
          <HorizontalSpace $amount={10} />
          {metadata.listenOn && metadata.listenOn.spotify && (
            <React.Fragment>
              <SpotifyLink song={metadata} />
              <HorizontalSpace $amount={5} />
            </React.Fragment>
          )}
          {metadata.listenOn && metadata.listenOn.appleMusic && (
            <React.Fragment>
              <AppleMusicLink song={metadata} />
              <HorizontalSpace $amount={5} />
            </React.Fragment>
          )}
          {metadata.listenOn && metadata.listenOn.soundcloud && (
            <React.Fragment>
              <SoundCloudLink song={metadata} />
              <HorizontalSpace $amount={5} />
            </React.Fragment>
          )}
          {metadata.listenOn && metadata.listenOn.youtube && (
            <React.Fragment>
              <YouTubeLink song={metadata} />
              <HorizontalSpace $amount={5} />
            </React.Fragment>
          )}
        </MetadataGroup>
        <MetadataGroup>
          <SubtleMetadata>{metadata.artist}</SubtleMetadata>
          <HorizontalSpace $amount={5} />
          <SubtleMetadata>{"·"}</SubtleMetadata>
          <HorizontalSpace $amount={5} />
          <SubtleMetadata>{metadata.year}</SubtleMetadata>
          <HorizontalSpace $amount={5} />
          <SubtleMetadata>{"·"}</SubtleMetadata>
          <HorizontalSpace $amount={5} />
          <SubtleMetadata>{metadata.publisher}</SubtleMetadata>
        </MetadataGroup>

        <Roles roles={metadata.roles} highlightRole={highlightRole} />
      </MetadataContainer>
    </ProjectContainer>
  );
};

export default Project;
