import React from "react";

import { data } from "../data.js";
import Project from "../components/Project";
import VerticalSpace from "../components/VerticalSpace";
import ProjectList from "../components/ProjectList";
import PageTitle from "../components/PageTitle";

const personalProjects = data.projects.filter(
  (project) => !project.isNotPersonalProject
);
const nonPersonalProjects = data.projects.filter(
  (project) => project.isNotPersonalProject === true
);

const OverviewView = () => (
  <React.Fragment>
    <PageTitle>Releases</PageTitle>
    <VerticalSpace $amount={20} />
    <ProjectList>
      {personalProjects.map((project) => (
        <Project metadata={project} key={project.id} />
      ))}
    </ProjectList>
    <VerticalSpace $amount={75} />
    <PageTitle>Various projects I've been a part of</PageTitle>
    <VerticalSpace $amount={20} />
    <ProjectList>
      {nonPersonalProjects.map((project) => (
        <Project metadata={project} key={project.id} />
      ))}
    </ProjectList>
  </React.Fragment>
);

export default OverviewView;
