import React from "react";
import styled, { keyframes } from "styled-components";
import theme from "../theme";

const colorfulRotation = keyframes`
  0% {
    color: #F28FE1;
  }

  20% {
    color: #A65A98;
  }

  40% {
    color: #51A671;
  }

  60% {
    color: #F2915E;
  }

  80% {
    color: #A66949;
  }

  100% {
    color: #F28FE1;
  }
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  font-size: ${theme.fontSizes.medium};
  color: ${({ theme }) => theme.primary};
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 4px;
  }

  @media (max-width: 650px) {
    font-size: ${theme.fontSizes.xsmall};
  }
`;

const Separator = styled.span`
  display: block;
  height: 30px;
  margin: 0 15px;
  border-left: 2px solid #888;
  border-color: #ddd;

  @media (max-width: 650px) {
    margin: 0 10px;
    border-left-width: 1px;
  }
`;

const InstagramUsername = styled.span`
  text-transform: lowercase;
  font-size: ${theme.fontSizes.small};
  color: #a7a7a7;
  animation: ${colorfulRotation} 6s linear infinite;

  @media (max-width: 650px) {
    display: none;
    font-size: ${theme.fontSizes.xsmall};
  }
`;

const InstagramIcon = styled.img`
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  min-height: 16px;
  max-height: 16px;
  margin-right: 10px;

  @media (max-width: 650px) {
    margin-right: 0;
  }
`;

const Wrapper = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 15px;
  box-shadow: 0px 0px 30px 5px rgba(255, 255, 255, 1);
  border: 1px solid #ddd;
  width: min-content;

  @media (max-width: 650px) {
    padding: 10px;
  }
`;

const Navbar = () => (
  <Wrapper>
    <Link href="/">Home</Link>
    <Separator />
    <Link href="/about">About</Link>
    <Separator />
    <Link href="/videos">Videos</Link>
    <Separator />
    <Link href="https://www.instagram.com/musikskaberig">
      <InstagramIcon src="/images/instagram-icon.png" />
      <InstagramUsername>@musikskaberig</InstagramUsername>
    </Link>
  </Wrapper>
);

export default Navbar;
