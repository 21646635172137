import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import gfm from "remark-gfm";

import PageTitle from "../components/PageTitle";
import withScrollToTopWhenMounted from "../components/withScrollToTopWhenMounted";
import aboutMd from "../markdown/about";
import theme from "../theme";

const MarkdownContainer = styled.div`
  max-width: 600px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
    color: #444;
    font-weight: bold;
    text-transform: uppercase;
    font-family: "Gaegu", sans-serif;
    margin: 0;
  }

  h1 {
    font-size: ${theme.fontSizes.giant};
    @media (max-width: 650px) {
      font-size: ${theme.fontSizes.xhuge};
    }
  }
  h2 {
    font-size: ${theme.fontSizes.xhuge};
    @media (max-width: 650px) {
      font-size: ${theme.fontSizes.huge};
    }
  }
  h3 {
    font-size: ${theme.fontSizes.huge};
    @media (max-width: 650px) {
      font-size: ${theme.fontSizes.xlarge};
    }
  }
  h4 {
    font-size: ${theme.fontSizes.xlarge};
    @media (max-width: 650px) {
      font-size: ${theme.fontSizes.large};
    }
  }
  h5 {
    font-size: ${theme.fontSizes.large};
    @media (max-width: 650px) {
      font-size: ${theme.fontSizes.medium};
    }
  }
  h6 {
    font-size: ${theme.fontSizes.medium};
    @media (max-width: 650px) {
      font-size: ${theme.fontSizes.small};
    }
  }

  p {
    line-height: 1.5;
    font-size: ${theme.fontSizes.medium};
  }

  hr {
    border: none;
    border-top: 5px solid #bbb;
    margin: 100px 0;
  }
`;

const AboutView = () => {
  return (
    <React.Fragment>
      <MarkdownContainer>
        <PageTitle>About</PageTitle>
        <ReactMarkdown plugins={[gfm]} rehypePlugins={[rehypeRaw]}>
          {aboutMd}
        </ReactMarkdown>
      </MarkdownContainer>
    </React.Fragment>
  );
};

export default withScrollToTopWhenMounted(AboutView);
