import React from "react";
import styled from "styled-components";
import theme from "../theme";

const Line = styled.span`
  font-size: ${theme.fontSizes.large};
  @media (max-width: 650px) {
    font-size: ${theme.fontSizes.medium};
  }
`;

const LyricsContainer = styled.div`
  max-width: 800px;
`;

const SongMeta = styled.div`
  margin: 1rem 0;
  color: #888;
`;

const SongTitle = styled.span`
  display: block;
  text-transform: uppercase;
  font-weight: bold;
`;

const Lyrics = ({ song }) => {
  const otherCredits =
    song.credits?.filter((c) =>
      [
        "cover art",
        "photography",
        "mixed",
        "mixer",
        "mixing",
        "mastered",
        "mastering",
        "master",
        "arranged",
        "arranger",
        "produced",
        "producer",
        "recorded",
        "recording",
        "recorder",
        "publisher",
      ].some((prefix) => c.label.toLowerCase().startsWith(prefix))
    ) ?? [];
  const musicCredits =
    song.credits?.filter(
      (c) => !otherCredits.map((c) => c.label).includes(c.label)
    ) ?? [];

  return (
    <LyricsContainer>
      <SongTitle>{song.title}</SongTitle>
      {song.description && (
        <SongMeta>
          <em>{song.description}</em>
        </SongMeta>
      )}
      {song.lyricsBy && (
        <SongMeta>
          <strong>Lyrics by:</strong> {song.lyricsBy}
        </SongMeta>
      )}
      {song.musicBy && (
        <SongMeta>
          <strong>Music by:</strong> {song.musicBy}
        </SongMeta>
      )}
      <br />
      {song.lyrics.map((part, lyricIndex) => {
        const texts = part.text.split(/\r?\n/);
        return (
          <React.Fragment key={`${song.id}-${lyricIndex}`}>
            {texts.map((text, lineIndex) => (
              <React.Fragment key={`${song.id}-${lyricIndex}-${lineIndex}`}>
                <Line>{text}</Line>
                <br />
              </React.Fragment>
            ))}
            <br />
          </React.Fragment>
        );
      })}
      {musicCredits.length > 0 && (
        <SongMeta>
          <strong>Credits:</strong>
          <ul style={{ margin: 0 }}>
            {musicCredits.map((c) => (
              <li key={c.label + c.content}>
                <strong>{c.content}:</strong> {c.label}
              </li>
            ))}
          </ul>
        </SongMeta>
      )}
      {otherCredits.length > 0 && (
        <SongMeta>
          <ul style={{ margin: 0 }}>
            {otherCredits.map((c) => (
              <li key={c.label + c.content}>
                <strong>{c.label}:</strong> {c.content}
              </li>
            ))}
          </ul>
        </SongMeta>
      )}
    </LyricsContainer>
  );
};

export default Lyrics;
