export const data = {
  projects: [
    {
      title: "In The Now",
      description: "",
      year: 2024,
      artist: "Maria Guttesen",
      type: "single",
      listenOn: {
        spotify:
          "https://open.spotify.com/track/0j6JgWoWIFjjUHgaiEq49I?si=QRReAsrJRtut48fzm7LcBA",
        youtube: "https://www.youtube.com/watch?v=aA3k_de4HTw",
      },
      songs: [
        {
          title: "In The Now",
          length: "04:19",
          id: "in-the-now-in-the-now-2024",
          description: "",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen",
          credits: [
            {
              label: "Backing Vocals",
              content: "Gunnar Gunnarson Guttesen",
            },
            {
              label: "Backing Vocals",
              content: "Regin Guttesen",
            },
            {
              label: "Violin",
              content: "Iben Østergaard Jensen",
            },
            {
              label: "Viola",
              content: "Theo Dan",
            },
            {
              label: "Cello",
              content: "Milica Obućina",
            },
            {
              label: "Bass clarinet and all other clarinets",
              content: "Anders Christensen",
            },
            {
              label: "Keyboard",
              content: "Henrik Lynbech",
            },
            {
              label: "Lead Vocal, piano, organ, drums and percussion",
              content: "Maria Guttesen",
            },
            {
              label: "Recorded, arranged and produced by",
              content: "Maria Guttesen",
            },
            { label: "Cover art", content: "Lene Drachmann" },
            { label: "Photography", content: "Maria Guttesen" },
            { label: "Mixing", content: "Henrik Lynbech" },
            {
              label: "Mastering",
              content: "Heini Ragnarsson (Ragnarsson-Audio, Faroe Islands)",
            },
            { label: "Publisher", content: "MGRoots (March 1, 2024)" },
          ],
          lyrics: [
            {
              label: "verse",
              text: "All You Need Is Love just where you are\nYou don't need good wishes from afar\nSaving moments now, that you unmask\nNo use in calling quarrels from the past",
            },
            {
              label: "verse",
              text: "Compassionately seeing you uncrowned\nLeaving all the bickering behind\nLiving this dynamic turn around\nI hold your hand. Save pictures in my mind",
            },
            {
              label: "verse",
              text: "I am here beside you in the now\nFilling up my heart\nI am here beside you in the now",
            },
            {
              label: "chorus",
              text: "[[: Oh oh oh oh - Let it go :]]\n\nUh hu uh hu",
            },
            {
              label: "verse",
              text: "Yes I'm well, I'm well, yes I am well\nYou don't need to know the grime combined\nIf I'm sad, I'll smile, so you can't tell\nBlink an eye, we lost a point in time",
            },
            {
              label: "verse",
              text: "I am here beside you in the now\nFilling up my heart\nI am here beside you in the now\nI am here beside you in the now\nFilling up my heart\nI am here beside you in the now",
            },
            {
              label: "chorus",
              text: "[[: Oh oh oh oh - Let it go :]]\n\nUh hu uh hu",
            },
          ],
        },
      ],
      publisher: "MGRoots",
      roles: [
        "lead vocal",
        "piano",
        "organ",
        "drums",
        "percussion",
        "arranger",
        "producer",
      ],
      img: "/in-the-now.jpg",
      id: "in-the-now-2024",
      isNotPersonalProject: false,
    },
    {
      title: "Christmas Kindness",
      description: "",
      year: 2022,
      artist: "Maria Guttesen",
      type: "single",
      listenOn: {
        spotify:
          "https://open.spotify.com/album/1tsZdApIO9tbosNj87jWbs?si=6UxY_5VSSC-Z_mQSNIbnGA",
        appleMusic:
          "https://music.apple.com/us/album/christmas-kindness-single/1654504900",
        youtube: "https://www.youtube.com/watch?v=QrTkFhuGEDk",
      },
      songs: [
        {
          title: "Christmas Kindness",
          length: "03:13",
          id: "christmas-kindness-christmas-kindness-2022",
          description: "",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen",
          credits: [
            {
              label: "Backing Vocals",
              content: "Gunnar Gunnarson Guttesen",
            },
            {
              label: "Cello",
              content: "Benedikte Borum Engell",
            },
            {
              label:
                "Lead Vocal and all other instruments, arranged, produced and mixed",
              content: "Maria Guttesen",
            },
            {
              label: "Mastering",
              content: "Kasper Sandberg",
            },
            { label: "Cover art", content: "Lene Drachmann" },
            { label: "Publisher", content: "MGRoots (November 18, 2022)" },
          ],
          lyrics: [
            {
              label: "verse",
              text:
                "Christmas time is back again\n" +
                "Like a dear but distant friend\n" +
                "Like a lantern in the darkness\n" +
                "That just never seems to end\n" +
                "Giving kindness",
            },
            {
              label: "verse",
              text:
                "Yuletide strums a song so clear\n" +
                "Christmas spices everywhere\n" +
                "Showing love all around you\n" +
                "Wrapping packages of cheer\n" +
                "Giving kindness",
            },
            {
              label: "verse",
              text:
                "Much about this time\n" +
                "We can never buy\n" +
                "Money has no worth\n" +
                "If we don't even try\n" +
                "Giving kindness",
            },
            {
              label: "verse",
              text:
                "Christmas time can take it's toll\n" +
                "If you are making it a show\n" +
                "If the fall out is a conquest\n" +
                "Posing selfless self-control\n" +
                "You need kindness",
            },
            {
              label: "verse",
              text:
                "Much about this time\n" +
                "We can never buy\n" +
                "Money has no worth\n" +
                "If we don't even try\n" +
                "Giving kindness",
            },
            {
              label: "verse",
              text:
                "Much about this time\n" +
                "We can never buy\n" +
                "Money has no worth\n" +
                "If we don't even try\n" +
                "Much about this time\n" +
                "We can never buy\n" +
                "Money has no worth\n" +
                "If we don't even try\n" +
                "Giving kindness",
            },
            {
              label: "verse",
              text:
                "Nothing's standing in the way\n" +
                "Of a kindness overlay\n" +
                "Why have focus on the getting\n" +
                "When the giving saves the day\n" +
                "Give some kindness",
            },
          ],
        },
      ],
      publisher: "MGRoots",
      roles: ["vocals"],
      img: "/christmas-kindness.webp",
      id: "christmas-kindsness-2022",
      isNotPersonalProject: false,
    },
    {
      title: "Big Bird",
      description: "",
      year: 2022,
      artist: "Maria Guttesen",
      type: "single",
      listenOn: {
        spotify:
          "https://open.spotify.com/track/2Y6AwD0oA281xMrBaOh4r7?si=0bac82a1e66f4b7f",
        appleMusic:
          "https://music.apple.com/us/album/big-bird-single/1648681889",
      },
      songs: [
        {
          title: "Big Bird",
          length: "03:48",
          id: "big-bird-big-bird-2022",
          description:
            "The song is about how you can feel trapped in a small cage, because of all the things that can get in the way of venting your creative urges.",
          lyricsBy: "Lene Drachmann, Jesse Aidan Hester, Maria Guttesen",
          musicBy: "Lene Drachmann, Jesse Aidan Hester, Maria Guttesen",
          credits: [
            {
              label: "Vocals",
              content: "Maria Guttesen",
            },
            {
              label: "All instruments",
              content: "Maria Guttesen",
            },
            {
              label: "Arranger",
              content: "Maria Guttesen",
            },
            {
              label: "Recorder",
              content: "Maria Guttesen",
            },
            {
              label: "Producer",
              content: "Maria Guttesen",
            },
            {
              label: "Mixing and mastering",
              content: "David Elberling",
            },
            {
              label: "Backing vocals",
              content: "Christian Sand Nielsen",
            },
            { label: "Cover art", content: "Lene Drachmann" },
            { label: "Publisher", content: "MGRoots (October 14, 2022)" },
          ],
          lyrics: [
            {
              label: "chorus",
              text: "One big bird\n" + "In a tiny cage\n" + "Breaking out",
            },
            {
              label: "verse",
              text:
                "The feeling is so symptomatic\n" +
                "Burning our glass of plastic\n" +
                "Seeing revelation passing by\n" +
                "The hole is fickle and socratic\n" +
                "Stopping the creative traffic\n" +
                "Frozen in the wake of hemlock's eye",
            },
            {
              label: "chorus",
              text: "One big bird\n" + "In a tiny cage\n" + "Breaking out",
            },
          ],
        },
      ],
      publisher: "MGRoots",
      roles: [
        "vocals",
        "drums",
        "synthesizer",
        "piano",
        "bass",
        "guitar",
        "arranger",
        "producer",
      ],
      img: "/big-bird.webp",
      id: "big-bird-2022",
      isNotPersonalProject: false,
    },
    {
      title: "Chip In",
      description: "",
      year: 2021,
      artist: "Maria Guttesen",
      type: "single",
      listenOn: {
        spotify:
          "https://open.spotify.com/album/3bAujGCqSBdBdvfK5Z0Cl5?si=LU4t-NSQRjmGUIACmH5xYg",
        appleMusic:
          "https://music.apple.com/us/album/chip-in-single/1589779099",
        youtube: "https://www.youtube.com/watch?v=_nVVf5N6ylM",
      },
      songs: [
        {
          title: "Chip In",
          length: "02:39",
          id: "chip-in-chip-in-2021",
          description:
            "Sangen ”Chip In” er en reaktion på menneskers håndtering af samfundsindet efter coronakrisens lockdown i 2020.\n" +
            "Vi blev alle blev fyldt med samfundssind og fællesskab. Sangen er en reaktion på hvor hurtige vi var til at give\n" +
            "fællesskabet op, og hytte vores eget og ikke tænke på resten.\n" +
            "Sangen er et ønske om at vi hver især gør alt hvad vi kan, og aldrig glemmer at vi har brug for andre. At de stærkeste\n" +
            "skuldre bærer mest, og at man ikke altid skal have noget til gengæld.",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen",
          credits: [
            {
              label: "Lead vocal",
              content: "Maria Guttesen",
            },
            {
              label: "All instruments",
              content: "Maria Guttesen",
            },
            {
              label: "Arranger",
              content: "Maria Guttesen",
            },
            {
              label: "Producer",
              content: "Maria Guttesen",
            },
            {
              label: "Backing vocals",
              content:
                "Christian Sand Nielsen – Katrine Stærk Olesen – Lene Drachmann – Henrik Lynbech – Christina Gramstrup Madsen",
            },
            { label: "Mixing", content: "Henrik Lynbech and Maria Guttesen" },
            { label: "Mastering", content: "Kasper Sandberg Larsen (K Mix)" },
            { label: "Cover art", content: "Lene Drachmann" },
            { label: "Publisher", content: "MGRoots (October 22, 2021)" },
          ],
          lyrics: [
            {
              label: "verse",
              text:
                "Is it possible\n" +
                "You'll consider me\n" +
                "Is it possible\n" +
                "Think of me",
            },
            {
              label: "verse",
              text:
                "Is it thinkable\n" +
                "That you need my help\n" +
                "Is it thinkable\n" +
                "Needing help?",
            },
            {
              label: "chorus",
              text:
                "You have to carry your own, but\n" +
                "No one's an island alone",
            },
            {
              label: "verse",
              text:
                "How things stand's a mess\n" +
                "We must all chip in\n" +
                "Status quo's a mess\n" +
                "All chip in",
            },
            {
              label: "verse",
              text:
                "What's the cost for you\n" +
                "To give more than me\n" +
                "What's the cost for you\n" +
                "You'll feel free",
            },
            {
              label: "chorus",
              text:
                "You have to carry your own, but\n" +
                "No one's an island alone\n" +
                "You have to carry your own, but\n" +
                "No one's an island alone",
            },
            {
              label: "verse",
              text:
                "You have to do the best that you can\n" +
                "and that won't be on your own\n" +
                "You have to hold a hand if you venture\n" +
                "Into the great unknown",
            },
            {
              label: "chorus",
              text:
                "You have to carry your own, but\n" +
                "No one's an island alone\n" +
                "You have to carry your own, but\n" +
                "No one's an island alone",
            },
          ],
        },
      ],
      publisher: "MGRoots",
      roles: [
        "vocals",
        "drums",
        "synthesizer",
        "piano",
        "bass",
        "guitar",
        "arranger",
        "producer",
      ],
      img: "/chip-in.webp",
      id: "chip-in-2021",
      isNotPersonalProject: false,
    },
    {
      title: "Drifting",
      description: "",
      year: 2021,
      artist: "HEIT, Maria Guttesen",
      type: "single",
      listenOn: {
        spotify: "https://open.spotify.com/album/4NYnhbvM8glo6ORQWSFc1F",
        appleMusic:
          "https://music.apple.com/us/album/drifting-feat-maria-guttesen-single/1562673061",
      },
      songs: [
        {
          title: "Drifting",
          length: "03:41",
          id: "drifting-drifting-2021",
        },
      ],
      publisher: "Accommodate",
      roles: ["featuring lead vocal"],
      img: "/drifting.jpg",
      id: "drifting-2021",
      isNotPersonalProject: true,
    },
    {
      title: "Síðsta Kvøldmáltíðin",
      description: "",
      year: 2020,
      artist: "Kaj Klein & Bendar Spónir",
      type: "album",
      listenOn: {
        spotify:
          "https://open.spotify.com/album/6oWZduYQfG30MeUoZ9XuOh?si=bSqifYRGQcurc2-zkS59TQ",
        appleMusic:
          "https://music.apple.com/us/album/s%C3%AD%C3%B0sta-kv%C3%B8ldm%C3%A1lt%C3%AD%C3%B0in/1525195418",
      },
      songs: [
        {
          title: "Slóð",
          length: "9:47",
          id: "sidsta-kvoeldmaltidin-slod-2020",
        },
        {
          title: "1. Mai",
          length: "5:05",
          id: "sidsta-kvoeldmaltidin-1-mai-2020",
        },
        {
          title: "Blús",
          length: "4:39",
          id: "sidsta-kvoeldmaltidin-blus-2020",
        },
        {
          title: "Take Away",
          length: "6:43",
          id: "sidsta-kvoeldmaltidin-take-away-2020",
        },
        {
          title: "Kontry",
          length: "4:58",
          id: "sidsta-kvoeldmaltidin-kontry-2020",
        },
        {
          title: "Illuminatimaður",
          length: "5:10",
          id: "sidsta-kvoeldmaltidin-illuminatimadur-2020",
        },
        {
          title: "Sól",
          length: "13:07",
          id: "sidsta-kvoeldmaltidin-sol-2020",
        },
      ],
      publisher: "Tutl",
      roles: ["backing vocals"],
      img: "/sidsta-kvoeldmaltidin.jpg",
      id: "sidsta-kvoeldmaltidin-2020",
      isNotPersonalProject: true,
    },
    {
      title: "Wander Away",
      description: "",
      year: 2020,
      artist: "GudrunSolja",
      type: "single",
      listenOn: {
        spotify:
          "https://open.spotify.com/album/27EDItuvsQ3gNTYyljFJlk?si=JjKXRYCtTxiCw0-ymQW41g",
        appleMusic: "https://music.apple.com/us/album/wander-away/1525348843",
      },
      songs: [
        {
          title: "Wander Away",
          length: "3:29",
          id: "wander-away-wander-away-2020",
          lyricsBy: "Lene Drachmann & Guðrun Sólja",
          musicBy: "Maria Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "Let's take a walk on your side\n" +
                "Where the grass is greener, ahhh\n" +
                "Through white flowered gardens\n" +
                "And climb knee-high fences\n" +
                "You take my hand and calm me down",
            },
            {
              label: "chorus",
              text: "Wander away",
            },
            {
              label: "verse",
              text:
                "Let's walk along the sea shore\n" +
                "Where the magic sea roar, ahhh\n" +
                "We will sit in the sand\n" +
                "And make up stories by ear\n" +
                "Sing me a song to calm me down",
            },
            {
              label: "chorus",
              text: "Wander away",
            },
            {
              label: "verse",
              text:
                "We will run with the horses\n" +
                "Count the sheep and\n" +
                "Give them names, ahhh\n" +
                "One by one\n" +
                "On the wide open field\n" +
                "You set me free to calm me down",
            },
            {
              label: "chorus",
              text: "Wander away",
            },
          ],
        },
      ],
      publisher: "GudrunSolja",
      roles: ["composer"],
      img: "/wander-away.jpg",
      id: "wander-away-2020",
      isNotPersonalProject: true,
    },
    {
      title: "Don't Make Do",
      description: "",
      year: 2019,
      artist: "Maria Guttesen",
      type: "single",
      listenOn: {
        spotify:
          "https://open.spotify.com/album/6qhcpeG7PiavwT06U8JaNF?si=WAPWs3bzS8OIrMEGvS8zPA",
        appleMusic:
          "https://music.apple.com/us/album/dont-make-do-single/1482701632",
        youtube: "https://www.youtube.com/watch?v=Ns-GO7oGy-k",
      },
      songs: [
        {
          title: "Don't Make Do",
          length: "4:06",
          id: "steenild-guttesen-dont-make-do-2019",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "Don't make do\n" +
                "this is a maker's world\n" +
                "Life loves you\n" +
                "make of life\n" +
                "what you want to leave behind, too",
            },
            {
              label: "chorus",
              text:
                "I can but hold your hand\n" +
                "infuse my courage\n" +
                "I can help lift your eyes\n" +
                "towards your dreams in the sky\n" +
                "Draw a map together is all I\n" +
                "can help you do",
            },
            {
              label: "verse",
              text:
                "Don't just buy\n" +
                "acquiring blindly æh æh\n" +
                "Paint your sky\n" +
                "with wishes and dreams denied",
            },
            {
              label: "chorus",
              text:
                "I can but hold your hand\n" +
                "infuse my courage\n" +
                "I can help lift your eyes\n" +
                "towards your dreams in the sky\n" +
                "Draw a map together is all I\n" +
                "can help you do",
            },
            {
              label: "verse",
              text:
                "If conformity is all you need\n" +
                "I will leave you be or break what's\n" +
                "broken into three",
            },
            {
              label: "chorus",
              text:
                "I can but hold your hand\n" +
                "infuse my courage\n" +
                "I can help lift your eyes\n" +
                "towards your dreams in the sky\n" +
                "Draw a map together is all I\n" +
                "can help you do",
            },
            {
              label: "verse",
              text:
                "Fear close up\n" +
                "boxes filling your mind\n" +
                "Bend the highway lines with arrows\n" +
                "leading most of mankind",
            },
            {
              label: "chorus",
              text:
                "I can but hold your hand\n" +
                "infuse my courage\n" +
                "I can help lift your eyes\n" +
                "towards your dreams in the sky\n" +
                "I can but hold your hand\n" +
                "infuse my courage\n" +
                "I can help lift your eyes\n" +
                "towards your dreams in the sky\n" +
                "Draw a map together is all I\n" +
                "can help you do",
            },
            {
              label: "outro",
              text: "Oh... oh...oh oh oh oh...",
            },
            {
              label: "outro",
              text: "Oh... oh...oh oh oh oh... oh... oh...",
            },
            {
              label: "outro",
              text: "Dæ ba ra dym dæ... ba dæ ba ba re ba ra dym de ba re ba ba re bæ bæ ræ be",
            },
          ],
        },
      ],
      publisher: "MGRoots",
      roles: [
        "vocals",
        "guitar",
        "bass",
        "drums",
        "drumpads",
        "percussion",
        "keyboard",
        "arranger",
        "producer",
        "mixer",
      ],
      img: "/dont-make-do.jpg",
      id: "dont-make-do-2019",
      isNotPersonalProject: false,
    },
    {
      title: "Hello Friend",
      description: "",
      year: 2019,
      artist: "Steenild+Guttesen",
      type: "single",
      listenOn: {
        spotify:
          "https://open.spotify.com/album/4HJdr5BuzMn8Ja7mEAkTLk?si=UWH8rVTmQPey4ywVVR_Lvg",
        appleMusic:
          "https://music.apple.com/us/album/hello-friend-single/1481998159",
      },
      songs: [
        {
          title: "Hello Friend",
          length: "4:14",
          id: "steenild-guttesen-hello-friend-2019",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen & Peter Steenild",
          lyrics: [
            {
              label: "chorus",
              text:
                "My love is full disclosure friend\n" +
                "Isn't or or either friend — I care",
            },
            {
              label: "verse",
              text:
                "Hello — hello\n" +
                "Telling you that I'll come by\n" +
                "Hello — hello\n" +
                "I will listen as you cry",
            },
            {
              label: "chorus",
              text:
                "My love is full disclosure friend\n" +
                "Isn't or or either friend — I care\n" +
                "I care",
            },
            {
              label: "verse",
              text:
                "Hello\n" +
                "Yes I see you on the floor\n" +
                "Hello\n" +
                "Together we'll ride out the storm",
            },
            {
              label: "verse",
              text:
                "I do care I do my friend\n" +
                "I do care for you my friend — I care",
            },
            {
              label: "chorus",
              text:
                "My love is full disclosure friend\n" +
                "Isn't or or either friend — I care\n" +
                "I care",
            },
            {
              label: "verse",
              text:
                "Hello\n" +
                "I will hold you all the way\n" +
                "Hello\n" +
                "Not just care for you today",
            },
            {
              label: "chorus",
              text:
                "My love is full disclosure friend\n" +
                "Isn't or or either friend — I care\n" +
                "I care\n" +
                "My love is full disclosure friend\n" +
                "Isn't or or either friend — I care\n" +
                "I care\n" +
                "My love is full disclosure friend\n" +
                "Isn't or or either friend — I care\n" +
                "I care\n" +
                "I do care I do my friend\n" +
                "I do care for you my friend — I care",
            },
            {
              label: "outro",
              text: "Ooooohhhhhh.... Ooooohhhhhh.... Ooooohhhhhh....",
            },
          ],
        },
      ],
      publisher: "MGRoots",
      roles: [
        "vocals",
        "drums",
        "keyboard",
        "stomp box",
        "organ",
        "producer",
        "mixer",
      ],
      img: "/hello-friend.jpg",
      id: "hello-friend-2019",
      isNotPersonalProject: false,
    },
    {
      title: "With A Little Love (Christmas 2018)",
      description: "",
      year: 2018,
      artist: "The Broken Beats",
      type: "single",
      listenOn: {
        spotify:
          "https://open.spotify.com/album/60HkrkKRXOpooVT3wx3xBl?si=_5mBSKCKSXSu9i9RYQVR5g",
        appleMusic:
          "https://itunes.apple.com/dk/album/with-a-little-love-christmas-2018/1444593455",
      },
      songs: [
        {
          title: "With A Little Love - Christmas 2018",
          length: "3:40",
          id: "the-broken-beats-with-a-little-love-christmas-2018",
        },
      ],
      publisher: "Target Records",
      roles: ["vocals"],
      img: "/with-a-little-love.jpg",
      id: "with-a-little-love-christmas-2018",
      isNotPersonalProject: true,
    },
    {
      title: "Childlike",
      description:
        "Collaboration with Lene Drachmann and Peter Steenild. The music is composed by Peter Steenild and Maria Guttesem. The lyrics are by Lene Drachmann.",
      year: 2018,
      artist: "Steenild+Guttesen",
      type: "single",
      listenOn: {
        spotify:
          "https://open.spotify.com/album/0u44PgXqfVizLispTs3alA?si=Ea2vBh6aTG-GkgXgW1sQdA",
        appleMusic:
          "https://itunes.apple.com/us/album/childlike-single/1390252405",
      },
      songs: [
        {
          title: "Childlike",
          length: "4:00",
          id: "childlike-childlike",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen & Peter Steenild",
          lyrics: [
            {
              label: "verse",
              text:
                "You never have to pay for what you say\n" +
                "As long as what you say is hushed away",
            },
            {
              label: "chorus",
              text:
                "The intelligent man is silent\n" +
                "said the priest to folks like me\n" +
                "Yeah I know I'm often childlike\n" +
                "but I disagree",
            },
            {
              label: "verse",
              text:
                "It sounds like wisdom comes\n" +
                "in coward skin\n" +
                "And spoken truth is just a foolish kin",
            },
            {
              label: "chorus",
              text:
                "The intelligent man is silent\n" +
                "said the priest to folks like me\n" +
                "Yeah I know I'm often childlike\n" +
                "but I disagree",
            },
            {
              label: "verse",
              text:
                "I walked the riverside\n" +
                "where scholars rest\n" +
                "A clever silents fault\n" +
                "are unexpressed",
            },
            {
              label: "chorus",
              text:
                "The intelligent man is silent\n" +
                "said the priest to folks like me\n" +
                "Yeah I know I'm often childlike\n" +
                "but I disagree",
            },
            { label: "outro", text: "" },
          ],
        },
      ],
      publisher: "MGRoots",
      roles: [
        "composer",
        "vocals",
        "drums",
        "bass",
        "synthesizer",
        "piano",
        "producer",
        "mixer",
      ],
      img: "/childlike.jpg",
      id: "childlike-2018",
    },
    {
      title: "Monkey Man",
      description: "",
      year: 2014,
      artist: "Reversed Effort",
      type: "single",
      listenOn: {
        spotify:
          "https://open.spotify.com/album/5vK7mWrXm52f74vZbI2BL5?si=JYQFrCnFTDKBfNGA6AtHVw",
        appleMusic:
          "https://itunes.apple.com/us/album/monkey-man-single/871699436",
      },
      songs: [
        {
          title: "Monkey Man",
          length: "4:00",
          id: "monkey-man-monkey-man",
        },
      ],
      publisher: "MGRoots",
      roles: ["synthesizer", "producer"],
      img: "/monkey-man.jpg",
      id: "monkey-man-2014",
      isNotPersonalProject: true,
    },
    {
      title: "Revelations",
      description: "",
      year: 2014,
      artist: "Reversed Effort",
      type: "single",
      listenOn: {
        spotify:
          "https://open.spotify.com/album/1jrVV5kEF7UJok8qrOSBC3?si=ZNNvO1WQTBqpk6f2OoRsfQ",
        appleMusic:
          "https://itunes.apple.com/us/album/revelations-single/802902467",
      },
      songs: [
        {
          title: "Revelations",
          length: "4:00",
          id: "revelations-revelations",
        },
      ],
      publisher: "MGRoots",
      roles: ["synthesizer", "producer"],
      img: "/revelations.jpg",
      id: "revelations-2014",
      isNotPersonalProject: true,
    },
    {
      title: "Í andglettinum",
      description: "",
      year: 2012,
      artist: "Kaj Klein",
      type: "album",
      listenOn: {
        spotify: "https://open.spotify.com/album/0g1ES7HRxKOBSplli5y4GK",
        appleMusic:
          "https://itunes.apple.com/dk/album/%C3%AD-andglettinum/609093735",
      },
      songs: [
        {
          title: "Sigi sum er",
          length: "3:48",
          id: "i-andglettinum-sigi-sum-er",
        },
        {
          title: "Á veg heim",
          length: "4:41",
          id: "i-andglettinum-a-veg-heim",
        },
        {
          title: "Tíðarinnar káma tonn",
          length: "4:14",
          id: "i-andglettinum-tidarinnar-koma-tonn",
        },
        {
          title: "Búrið",
          length: "5:23",
          id: "i-andglettinum-burid",
        },
        {
          title: "Rasmina",
          length: "4:29",
          id: "i-andglettinum-rasmina",
        },
        {
          title: "Siti her",
          length: "3:56",
          id: "i-andglettinum-siti-her",
        },
        {
          title: "Sjómanna prát",
          length: "4:15",
          id: "i-andglettinum-sjomanna-prat",
        },
        {
          title: "Vánaveður",
          length: "5:02",
          id: "i-andglettinum-vanavedur",
        },
      ],
      publisher: "Tutl",
      roles: ["backing vocals"],
      img: "/i-andglettinum.jpg",
      id: "i-andglettinum-2012",
      isNotPersonalProject: true,
    },
    {
      title: "The First Four",
      description: "",
      year: 2012,
      artist: "GudrunSolja",
      type: "ep",
      listenOn: {
        spotify:
          "https://open.spotify.com/album/2L9vsbiNRX5XVfG62syW7t?si=N8X9xRHnSfWVRQrVNmehdg",
        appleMusic:
          "https://itunes.apple.com/us/album/the-first-four-ep/572551642",
      },
      songs: [
        {
          title: "Got That Smile Again",
          length: "2:38",
          id: "the-first-four-got-that-smile-again",
          lyricsBy: "Lene Drachmann & Guðrun Sólja",
          musicBy: "Maria Guttesen & Guðrun Sólja",
          lyrics: [
            {
              label: "verse",
              text:
                "So you moved on \n" +
                "with a new supporting actress\n" +
                "oh so gracefully\n" +
                "looking happy, got that smile again",
            },
            {
              label: "verse",
              text:
                "Living in your shadow\n" +
                "without ever really knowing\n" +
                "is she just for ypu\n" +
                "to make you feel like a better man",
            },
            {
              label: "verse",
              text:
                "Was it just the mix of us\n" +
                "that made you weep an ocean\n" +
                "and will the mix of you and her\n" +
                "keep you afloat",
            },
            {
              label: "verse",
              text:
                "Do you ever wonder \n" +
                "how I am doing now\n" +
                "you left me cold\n" +
                "with no solid ground\n" +
                "to start out from",
            },
            {
              label: "verse",
              text:
                "Thinking more of her,\n" +
                "she must be something perfect\n" +
                "oh so graceful\n" +
                "looking happy, got that smile again",
            },
            {
              label: "verse",
              text:
                "Was it just the mix of us\n" +
                "that made you weep an ocean\n" +
                "and will the mix of you and her\n" +
                "keep you afloat",
            },
            {
              label: "verse",
              text:
                "I wish you well, but don't be mistaken\n" +
                "bitterness still lives in me\n" +
                "I wish you well, but don't be mistaken\n" +
                "bitterness still lives in me",
            },
            {
              label: "verse",
              text:
                "Was it just the mix of us\n" +
                "that made you weep an ocean\n" +
                "and will the mix of you and her\n" +
                "keep you afloat",
            },
            {
              label: "verse",
              text: "I wish you well\n" + "But bitterness still lives in me",
            },
          ],
        },
        {
          title: "If I Could",
          length: "4:07",
          id: "the-first-four-if-i-could",
          lyricsBy: "Guðrun Sólja & Lene Drachmann",
          musicBy: "Guðrun Sólja & Maria Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "If I could I would  take away your pain\n" +
                "If I could I would take away your sorrow\n" +
                "If I could I would melt away all your fears\n" +
                "If I could - if I could",
            },
            {
              label: "verse",
              text:
                "If there's a day that makes you feel cold\n" +
                "I will blow up the sun and turn up the heat\n" +
                "If I could I would take the sparkle from the stars\n" +
                "and put them in your eyes\n" +
                "To give them back the life they use to have",
            },
            {
              label: "verse",
              text:
                "But I can wipe away your tears\n" +
                "And I will hold you while your weeping\n" +
                "Oh yes I would",
            },
            {
              label: "verse",
              text:
                "If I could I'd restore our inner beat\n" +
                "I would grant you grace and then I set you free\n" +
                "If I could I would voice his sign of absolution\n" +
                "If I could \n" +
                "you know I would",
            },
            {
              label: "verse",
              text:
                "But I can wipe away your tears\n" +
                "And I will hold you while your weeping\n" +
                "Oh yes I would",
            },
          ],
        },
        {
          title: "Lack of Words",
          length: "3:11",
          id: "the-first-four-lack-of-words",
          lyricsBy: "Lene Drachmann & Guðrun Sólja",
          musicBy: "Maria Guttesen & Guðrun Sólja",
          lyrics: [
            {
              label: "verse",
              text:
                "My inspiration has run out\n" +
                "floating at the bottom of the bottle,\n" +
                "I try to catch it with a swig - ahh",
            },
            {
              label: "verse",
              text:
                "What is it I that I am in need of\n" +
                "Something stops me from getting further\n" +
                "'Cause further is where I wanna go - ahh",
            },
            {
              label: "verse",
              text:
                "What is it I'm missing\n" +
                "What do I need\n" +
                "What does it take to get me there",
            },
            {
              label: "verse",
              text:
                "Thought I wanna share but I lack the words\n" +
                "coming out as tears instead\n" +
                "But this I can not hide anymore - ahh",
            },
            {
              label: "verse",
              text:
                "What is it I'm missing\n" +
                "What do I need\n" +
                "What does it take to get me there",
            },
            {
              label: "verse",
              text:
                "What is it I'm missing\n" +
                "What do I need\n" +
                "What does it take to get me there",
            },
            { label: "verse", text: "Uhuuhhhh uh uh uh uh x 4" },
            {
              label: "verse",
              text:
                "What is it I'm missing\n" +
                "What do I need\n" +
                "What does it take to get me there",
            },
          ],
        },
        {
          title: "Silent Cue",
          length: "5:00",
          id: "the-first-four-silent-cue",
          lyricsBy: "Lene Drachmann & Guðrun Sólja",
          musicBy: "Maria Guttesen & Guðrun Sólja",
          lyrics: [
            {
              label: "verse",
              text:
                "She crosses her legs and wonders why\n" +
                "the sky is blue\n" +
                "She crosses her arms to cradle truth\n" +
                "her youth is through",
            },
            {
              label: "verse",
              text:
                "The place that she knows\n" +
                "her self is erupting\n" +
                "it will be a silent cue to\n" +
                "a new theme",
            },
            {
              label: "verse",
              text:
                "Benched outside and smiling sweet\n" +
                "shuffling her feet\n" +
                "as complete",
            },
            {
              label: "verse",
              text:
                "The place that she knows\n" +
                "her self is erupting\n" +
                "it will be a silent cue to\n" +
                "a new theme",
            },
            {
              label: "verse",
              text:
                "They don't see just how she flows\n" +
                "away from home\n" +
                "How it will go, she doesn't know\n" +
                "She destroyed it all",
            },
            {
              label: "verse",
              text:
                "The place that she knows\n" +
                "her self is erupting\n" +
                "it will be a silent cue to\n" +
                "a new theme",
            },
          ],
        },
      ],
      publisher: "MGRoots",
      roles: [
        "composer",
        "drums",
        "bass",
        "guitar",
        "backing vocals",
        "percussion",
        "piano",
        "synthesizer",
        "producer",
      ],
      img: "/the-first-four.jpg",
      id: "the-first-four-2012",
      isNotPersonalProject: true,
    },
    {
      title: "Got That Smile Again",
      description: "",
      year: 2012,
      artist: "GudrunSolja",
      type: "single",
      listenOn: {
        spotify:
          "https://open.spotify.com/album/2L9vsbiNRX5XVfG62syW7t?si=N8X9xRHnSfWVRQrVNmehdg",
        appleMusic:
          "https://open.spotify.com/album/3npgmLilBaqs715oxEa6NL?si=S19cFh3bRK-XDO3C1O96Gg",
      },
      songs: [
        {
          title: "Got That Smile Again",
          length: "2:38",
          id: "got-that-smile-again--got-that-smile-again",
          lyricsBy: "Lene Drachmann & Guðrun Sólja",
          musicBy: "Maria Guttesen & Guðrun Sólja",
          lyrics: [
            {
              label: "verse",
              text:
                "So you moved on \n" +
                "with a new supporting actress\n" +
                "oh so gracefully\n" +
                "looking happy, got that smile again",
            },
            {
              label: "verse",
              text:
                "Living in your shadow\n" +
                "without ever really knowing\n" +
                "is she just for ypu\n" +
                "to make you feel like a better man",
            },
            {
              label: "verse",
              text:
                "Was it just the mix of us\n" +
                "that made you weep an ocean\n" +
                "and will the mix of you and her\n" +
                "keep you afloat",
            },
            {
              label: "verse",
              text:
                "Do you ever wonder \n" +
                "how I am doing now\n" +
                "you left me cold\n" +
                "with no solid ground\n" +
                "to start out from",
            },
            {
              label: "verse",
              text:
                "Thinking more of her,\n" +
                "she must be something perfect\n" +
                "oh so graceful\n" +
                "looking happy, got that smile again",
            },
            {
              label: "verse",
              text:
                "Was it just the mix of us\n" +
                "that made you weep an ocean\n" +
                "and will the mix of you and her\n" +
                "keep you afloat",
            },
            {
              label: "verse",
              text:
                "I wish you well, but don't be mistaken\n" +
                "bitterness still lives in me\n" +
                "I wish you well, but don't be mistaken\n" +
                "bitterness still lives in me",
            },
            {
              label: "verse",
              text:
                "Was it just the mix of us\n" +
                "that made you weep an ocean\n" +
                "and will the mix of you and her\n" +
                "keep you afloat",
            },
            {
              label: "verse",
              text: "I wish you well\n" + "But bitterness still lives in me",
            },
          ],
        },
      ],
      publisher: "MGRoots",
      roles: [
        "composer",
        "drums",
        "bass",
        "guitar",
        "backing vocals",
        "percussion",
        "piano",
        "synthesizer",
        "producer",
      ],
      img: "/got-that-smile-again.jpg",
      id: "got-that-smile-again-2012",
      isNotPersonalProject: true,
    },
    {
      title: "Reconstructed",
      description: "",
      year: 2010,
      artist: "Steenild+Guttesen",
      type: "album",
      listenOn: {
        spotify:
          "https://open.spotify.com/album/1aQ6Sq33sJm2UZWbhW0dVm?si=Hw9DC8ItTe-QhHlIEaBGAg",
        appleMusic: "https://itunes.apple.com/us/album/reconstructed/357814227",
      },
      songs: [
        {
          title: "Fuglar og Kavi",
          length: "3:51",
          id: "reconstructed-fuglar-og-kavi",
          lyricsBy: "Elinbjørg Højgaard",
          musicBy: "Maria Guttesen & Peter Steenild",
          lyrics: [
            {
              label: "verse",
              text:
                "Nú liggur kavin so reinur og hvítur\n" +
                "og kuldin fast í nøsini bítur\n" +
                "illa gongst fuglinun føði at finna\n" +
                "tí ringt er í rokinun fram at vinna",
            },
            {
              label: "verse",
              text:
                "Kasta tí út nakrar avlops molar\n" +
                "áðrenn tú føt og  tallerkarnar skolar\n" +
                "tá skalt tú síggja fuglana gleðið\n" +
                "tí hetta teir styrkjir og gevur megi",
            },
          ],
        },
        {
          title: "Once Young",
          length: "3:50",
          id: "reconstructed-once-young",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen & Peter Steenild",
          lyrics: [
            {
              label: "verse",
              text:
                "I trust the darkness to humble me\n" +
                "I wait for closure to let me go free\n" +
                "Candid on paper – Stale to the touch",
            },
          ],
        },
        {
          title: "Waking Up",
          length: "4:07",
          id: "reconstructed-waking-up",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen & Peter Steenild",
          lyrics: [
            {
              label: "verse",
              text:
                "Everything around me is quite fabulous,\n" +
                "Selfless duvet hugs delightfully\n" +
                "I'm flying high in sleep\n" +
                "Got no anxiety\n" +
                "But dawn is threatening my sanctuary",
            },
            {
              label: "verse",
              text:
                "Lights are flickering my bliss away\n" +
                "Forgotten pesterings and fears reappear\n" +
                "Dogmatic jolts in play\n" +
                "And pulling on my hair\n" +
                "Something's up,\n" +
                "I cannot stay here ",
            },
          ],
        },
        {
          title: "Babba",
          length: "2:24",
          id: "reconstructed-babba",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen & Peter Steenild",
          lyrics: [
            {
              label: "verse",
              text:
                "I need you now\n" +
                "To hold my hand\n" +
                "The world out there\n" +
                "Is hard to stand",
            },
            {
              label: "verse",
              text:
                "I need to hide\n" +
                "In your fatherly arms\n" +
                "Too strong to let me fall\n" +
                "Or fly to the sky\n" +
                "Safety will die\n" +
                "I feel unsure\n" +
                "Out there",
            },
            {
              label: "verse",
              text:
                "I see you go\n" +
                "Away from here\n" +
                "Away from me\n" +
                "Away from me now",
            },
            {
              label: "verse",
              text:
                "Far away oh\n" +
                "Where you have been set free\n" +
                "But daddy I've been stuck back here\n" +
                "I will go see the world\n" +
                "Without your help\n" +
                "You see - I'm free - I'm free",
            },
          ],
        },
        {
          title: "Pipe Dream",
          length: "3:34",
          id: "reconstructed-pipe-dream",
          lyricsBy: "Karoline Hausted",
          musicBy: "Karoline Hausted & Peter Steenild",
          lyrics: [
            {
              label: "verse",
              text:
                "Four in the morning\n" +
                "Just before dawn\n" +
                "I listen to the never ending traffic",
            },
            {
              label: "verse",
              text:
                "The tv set flashes\n" +
                "The rest of me craches\n" +
                "I listen to the sound in my ears pounding",
            },
            {
              label: "verse",
              text:
                "I'm falling into a pipe dream\n" +
                "I'm falling into a pipe dream\n" +
                "Where no one can find me",
            },
            {
              label: "verse",
              text:
                "Used to believe\n" +
                "I could be anything\n" +
                "Obstacles - I would be an athlete",
            },
            {
              label: "verse",
              text:
                "One giant leap\n" +
                "Perfect and weightless\n" +
                "I am the laziest girl in the apartment",
            },
            { label: "verse", text: "I'm falling..." },
          ],
        },
        {
          title: "Bath Tub",
          length: "2:49",
          id: "reconstructed-bath-tub",
          lyricsBy: "Karoline Hausted",
          musicBy: "Karoline Hausted & Peter Steenild",
          lyrics: [
            {
              label: "verse",
              text:
                "Baby drizzles out the powdered soap\n" +
                "Longing for the evening air\n" +
                "To wind itself around her\n" +
                "Thunder roll\n" +
                "Towel on the floor\n" +
                "Clouds of vapour glide\n" +
                "Through the open door",
            },
            {
              label: "verse",
              text:
                "Here I go descending\n" +
                "Here I feel my weight go\n" +
                "I need to float away\n" +
                "Thought I had a way of floating too",
            },
            {
              label: "verse",
              text:
                "Baby's sitting in the dragon's lair\n" +
                "Seven pairs of gentle hands\n" +
                "Cuddle up her hair\n" +
                "Wash and make it foam\n" +
                "Carefully dry and softly comb",
            },
            {
              label: "verse",
              text:
                "Baby's throwing lilies in the pond\n" +
                "One for each and every scare\n" +
                "That circles on the steaming water front\n" +
                "All drifting now\n" +
                "Old sorrow seems\n" +
                "To leave here somehow",
            },
          ],
        },
        {
          title: "Peace of Mind",
          length: "2:59",
          id: "reconstructed-peace-of-mind",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen & Peter Steenild",
          lyrics: [
            {
              label: "verse",
              text:
                "Go with me now\n" +
                "Come with me now\n" +
                "aaaaahhhh aaaahhh\n" +
                "See how\n" +
                "One touch so kind\n" +
                "Will make you mine\n" +
                "Aaahhh aaaahhhh\n" +
                "If you",
            },
            {
              label: "chorus",
              text: "Hold me I'll give you peace of mind",
            },
            {
              label: "verse",
              text:
                "Dive into lips\n" +
                "Come dive in me\n" +
                "aaahhh aaaahhh\n" +
                "Skating\n" +
                "Life in a dance\n" +
                "Give me your hand\n" +
                "Aaaahhh aaaahhhh\n" +
                "If you",
            },
            {
              label: "chorus",
              text: "Hold me I'll give you peace of mind",
            },
            {
              label: "verse",
              text:
                "Your sanity\n" +
                "Is made flesh within me\n" +
                "aaaahhhh aaaahhh\n" +
                "See how\n" +
                "Perfect you feel\n" +
                "Perfectly real\n" +
                "Ahhh aaahhh\n" +
                "If you",
            },
            {
              label: "chorus",
              text: "Hold me I'll give you peace of mind",
            },
          ],
        },
        {
          title: "Reconstructing",
          length: "4:24",
          id: "reconstructed-reconstructing",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen & Peter Steenild",
          lyrics: [
            {
              label: "verse",
              text:
                "Rewriting this play of mine\n" +
                "Cleaning up the tiles of time\n" +
                "I'm remaking\n" +
                "No more faking - Now",
            },
            { label: "verse", text: "Da da da..." },
            {
              label: "verse",
              text:
                "I died to wake up for real\n" +
                "You jumbled me good, to heal\n" +
                "Second chancing\n" +
                "reconstructing - Me",
            },
          ],
        },
        {
          title: "Moon Cow Love",
          length: "4:15",
          id: "reconstructed-moon-cow-love",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen & Peter Steenild",
          lyrics: [
            {
              label: "verse",
              text:
                "I wish I was incredibly brilliant\n" +
                "In with the crowd not blank like an infant\n" +
                "Ravishing and mind blowing star\n" +
                "Clever talking just like you are",
            },
            {
              label: "verse",
              text:
                "But I'm encasing me within\n" +
                "Boxed up matter and weathered skin\n" +
                "Moon cow love and bashful eyes\n" +
                "A girl with haunting sighs\n" +
                "Enfolding longing thighs",
            },
            {
              label: "verse",
              text:
                "Shaking and clammy I wait for your light\n" +
                "Dubious aim must have taken your sight\n" +
                "But I will be a pearl to find\n" +
                "'Cause I think you picked right",
            },
            {
              label: "verse",
              text:
                "I move slowly on - 'cause you want me\n" +
                "I float pleased along - 'cause you want me\n" +
                "I see nothing wrong - 'cause you want me",
            },
          ],
        },
        {
          title: "Magic Cruises",
          length: "5:51",
          id: "reconstructed-magic-cruises",
          lyricsBy: "Karoline Hausted",
          musicBy: "Karoline Hausted & Peter Steenild",
          lyrics: [
            {
              label: "verse",
              text:
                "Moon sand and star beams\n" +
                "And cat paws and jew straws\n" +
                "A handful of wing bones\n" +
                "A mouthful of gem stones\n" +
                "Scarecrows and no gos\n" +
                "And hay lows and low gos - On line",
            },
            {
              label: "verse",
              text:
                "Look in your mailbox\n" +
                "For peacocks and whale flocks\n" +
                "For toy cars and draw bars\n" +
                "And luna storms and bee swarms\n" +
                "Clock towers end hours\n" +
                "Stair cases faces on line",
            },
            {
              label: "verse",
              text:
                "Oh I'd love to\n" +
                "Love to be one on your\n" +
                "Magic cruises",
            },
            {
              label: "verse",
              text:
                "Oh I'd love to\n" +
                "Love to be one on your\n" +
                "Magic cruises",
            },
            {
              label: "verse",
              text:
                "Oh I'd love to\n" +
                "Love to be one on your\n" +
                "Magic cruises",
            },
          ],
        },
        {
          title: "Lurta",
          length: "2:16",
          id: "reconstructed-lurta",
          lyricsBy: "Maria Guttesen",
          musicBy: "Peter Steenild & Maria Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "Hoyri tónar við føgrum litum - myrkum litum -\n" +
                "ljósum litum - reinum litum - skitnum litum",
            },
            {
              label: "verse",
              text:
                "Blundið og lurtið\n" +
                "vindurin fýkur framvið\n" +
                "men fólkini ganga\n" +
                "tosa við hvønn annan\n" +
                "onkur er glaður - onkur er sorgarbundin -\n" +
                "onkur er tungur í sinninun - onkur grætur -\n" +
                "onkur flennur - onkur teskar og\n" +
                "onkur syngur vakrar tónar",
            },
            {
              label: "verse",
              text:
                "Frá gøtun - frá lítla rúminum heima - \n" +
                "stóra rúminum úti - \n" +
                "á landi - á sjónum - á fjalli - í fjósi",
            },
            {
              label: "verse",
              text:
                "Lurta enn einaferð\n" + "opna oyra - opna eygu og opna hjarta",
            },
          ],
        },
      ],
      publisher: "MGRoots",
      roles: [
        "composer",
        "vocals",
        "drums",
        "percussion",
        "producer",
        "lyricist",
      ],
      img: "/reconstructed.jpg",
      id: "reconstructed-2010",
    },
    {
      title: "Wide Open",
      description: "",
      year: 2008,
      artist: "Maria Guttesen",
      type: "album",
      listenOn: {
        spotify:
          "https://open.spotify.com/album/50q0Ovd0x8s0abGRvYcelU?si=NE6o5aqCQfCepNsWJ7ohQw",
        appleMusic: "https://itunes.apple.com/dk/album/wide-open/687502332",
      },
      songs: [
        {
          title: "Wide Open",
          length: "3:47",
          id: "wide-open-wide-open",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "I'm in love with trees\n" +
                "Peeling off the bark\n" +
                "As I'm told by my muse\n" +
                "An artist seduced",
            },
            {
              label: "verse",
              text:
                "I'm in love with walls\n" +
                "Dwelling in the cracks\n" +
                "Violence of time\n" +
                "A victimless crime",
            },
            {
              label: "verse",
              text:
                "My senses are open\n" +
                "Wide-eyed open\n" +
                "Gaping open\n" +
                "Blatantly open",
            },
            {
              label: "verse",
              text:
                "I'm in love with clouds\n" +
                "Drifting wide about\n" +
                "Cream frostingly rude\n" +
                "I'd touch if I could",
            },
            {
              label: "verse",
              text:
                "My senses are open\n" +
                "Wide-eyed open\n" +
                "Gaping open\n" +
                "Blatantly open",
            },
            {
              label: "verse",
              text:
                "I'm in love with streets and stones\n" +
                "and rain and bones and smily frowns\n" +
                "With kicked up dirt and laughs that hurt\n" +
                "The pinks and the browns",
            },
            {
              label: "verse",
              text:
                "My senses are open\n" +
                "Wide-eyed open\n" +
                "Gaping open\n" +
                "Blatantly open",
            },
            {
              label: "verse",
              text:
                "My senses are open\n" +
                "Wide-eyed open\n" +
                "Gaping open\n" +
                "Blatantly open",
            },
          ],
        },
        {
          title: "You Keep Me Sane",
          length: "4:25",
          id: "wide-open-you-keep-me-sane",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "It's six am\n" +
                "I still haven't slept\n" +
                "I am loosing track\n" +
                "of the time that I kept",
            },
            {
              label: "verse",
              text:
                "Pain in my head\n" +
                "Limbs aching\n" +
                "Most of the trouble\n" +
                "I myself am making",
            },
            {
              label: "verse",
              text:
                "I'm putting the world upon my own sholders\n" +
                "I'm taking junk that I didn't define\n" +
                "I'm liftin a burden I shouldn't be given\n" +
                "I'm picking up pieces that were never mine",
            },
            {
              label: "verse",
              text:
                "When night time is dark\n" +
                "You keep me sane\n" +
                "Your holding the light\n" +
                "Whispering my name",
            },
            {
              label: "verse",
              text:
                "I'm putting the world upon my own shoulders\n" +
                "I'm taking junk that I didn't define\n" +
                "I'm lifting a burden I shouldn't be given\n" +
                "I'm picking up pieces that were never mine",
            },
            {
              label: "verse",
              text:
                "The pressure is rising I have to say stop\n" +
                "if you hold my hand I think I can get up\n" +
                "The pressure is rising I have to say no\n" +
                "if you hold my hand I think I can let it all go",
            },
            {
              label: "verse",
              text:
                "I'm putting the world upon my own shoulders\n" +
                "I'm taking junk that I didn't define\n" +
                "I'm lifting a burden I shouldn't be given\n" +
                "I'm picking up pieces that were never mine",
            },
          ],
        },
        {
          title: "Luckily",
          length: "3:11",
          id: "wide-open-luckily",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen & Stephen Nørrelykke",
          lyrics: [
            {
              label: "verse",
              text: "Priority\n" + "Will you decide\n" + "Who it's going to be",
            },
            {
              label: "verse",
              text:
                "Honestly\n" + "I'd waste 10 lives\n" + "for one of quality",
            },
            {
              label: "verse",
              text:
                "Luckily I'm not God\n" +
                "I can go on living\n" +
                "Without a thought\n" +
                "Luckily it's allowed\n" +
                "to take the easy road\n" +
                "and rest in a cloud",
            },
            {
              label: "verse",
              text:
                "Unfortunately\n" + "Even with covered eyes\n" + "I can see",
            },
            {
              label: "verse",
              text:
                "Between you and me\n" +
                "Would you decide\n" +
                "Who it's going to be",
            },
            {
              label: "verse",
              text:
                "Luckily I'm not God\n" +
                "I can go on living\n" +
                "Without a thought\n" +
                "Luckily it's allowed\n" +
                "to take the easy road\n" +
                "and rest in a cloud",
            },
            {
              label: "verse",
              text:
                "Luckily I'm not God\n" +
                "I can go on living\n" +
                "Without a thought\n" +
                "Luckily it's allowed\n" +
                "to take the easy road\n" +
                "and rest in a cloud",
            },
            {
              label: "verse",
              text:
                "Between you and me\n" +
                "Would you decide\n" +
                "Who it's going to be",
            },
          ],
        },
        {
          title: "Danged the Things I Didn't Do",
          length: "3:47",
          id: "wide-open-danged-the-things-i-didnt-do",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "First it was fine\n" +
                "Second it was true\n" +
                "Then I considered her so much\n" +
                "I didn't consider you",
            },
            {
              label: "verse",
              text:
                "You were my friend\n" +
                "The best I'll ever know\n" +
                "But now you've gone and disappeared\n" +
                "like the melted snow",
            },
            {
              label: "verse",
              text:
                "Danged the things I didn't do\n" +
                "The letter never sent, though I missed you\n" +
                "I didn't stop running when you asked me to\n" +
                "Danged the things I didn't  do\n" +
                "Danged the things I didn't  do",
            },
            {
              label: "verse",
              text:
                "Please give me a call\n" +
                "if you still know my name\n" +
                "Take this late apology\n" +
                "I'll carry all the blame",
            },
            {
              label: "verse",
              text:
                "Danged the things I didn't do\n" +
                "The letter never sent, though I missed you\n" +
                "I didn't stop running when you asked me to\n" +
                "Danged the things I didn't  do\n" +
                "Danged the things I didn't  do",
            },
            {
              label: "verse",
              text:
                "Danged the things I didn't do\n" +
                "The letter never sent, though I missed you\n" +
                "I didn't stop running when you asked me to\n" +
                "Danged the things I didn't do\n" +
                "Danged the things I didn't do",
            },
          ],
        },
        {
          title: "8 Half Minutes",
          length: "2:38",
          id: "wide-open-8-half-minutes",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen & Stephen Nørrelykke",
          lyrics: [
            {
              label: "verse",
              text:
                "One two three\n" +
                "Why won't you see\n" +
                "That what I say\n" +
                "Is truth to me",
            },
            {
              label: "verse",
              text:
                "Four five six\n" +
                "I never mix\n" +
                "The hones face\n" +
                "With scamming tricks",
            },
            {
              label: "verse",
              text:
                "Scars and scratches\n" +
                "I can do retouches\n" +
                "But playing with matches\n" +
                "won't keep you dry \n" +
                "Your eyes water up\n" +
                "when you cry",
            },
            {
              label: "verse",
              text:
                "Seven eight\n" +
                "At this rate\n" +
                "This trustless race\n" +
                "A clingy mate",
            },
            {
              label: "verse",
              text:
                "Scars and scratches\n" +
                "I can do retouches\n" +
                "But playing with matches\n" +
                "won't keep you dry \n" +
                "Your eyes water up\n" +
                "when you cry",
            },
            {
              label: "verse",
              text:
                "Scars and scratches\n" +
                "I can do retouches\n" +
                "But playing with matches\n" +
                "won't keep you dry \n" +
                "Your eyes water up\n" +
                "when you cry",
            },
          ],
        },
        {
          title: "Magic to Be Two",
          length: "4:22",
          id: "wide-open-magic-to-be-two",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "When I try to write\n" +
                "Get out words of meaning\n" +
                "My pen runs out of ink\n" +
                "I speak without feeling\n" +
                "Until you sit at the piano\n" +
                "With a concentrated smile\n" +
                "I remember my soul\n" +
                "And it's all worth while",
            },
            {
              label: "verse",
              text:
                "Hearing you sing\n" +
                "Things that I have inside\n" +
                "This new dimension\n" +
                "Makes even the bad alright\n" +
                "baby you sit at the piano\n" +
                "sending me sporadic smiles\n" +
                "You are music in me\n" +
                "My love from the isles",
            },
            {
              label: "verse",
              text:
                "I've been just myself for so long\n" +
                "No warm heart to channel through\n" +
                "No inspiration for poetry\n" +
                "What magic to be two",
            },
            {
              label: "verse",
              text:
                "Singing lips I kiss\n" +
                "every time I think\n" +
                "Think in lines of rhyme\n" +
                "you and me in perfect sync\n" +
                "And you sit at the piano\n" +
                "I write by the light of your smile\n" +
                "Creating together\n" +
                "The song is our child",
            },
            {
              label: "verse",
              text:
                "I've been just myself for so long\n" +
                "No warm heart to channel through\n" +
                "No inspiration for poetry\n" +
                "What magic to be two",
            },
            {
              label: "verse",
              text:
                "I've been just myself for so long\n" +
                "No warm heart to channel through\n" +
                "No inspiration for poetry\n" +
                "What magic to be two",
            },
          ],
        },
        {
          title: "Fear of the Dark",
          length: "3:50",
          id: "wide-open-fear-of-the-dark",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "If you had a choice\n" +
                "would you know what to pick\n" +
                "Imagine you had a voice \n" +
                "facing a lunatic",
            },
            {
              label: "verse",
              text:
                "I'm walking faster\n" +
                "My pace is frantic\n" +
                "fear of darkness\n" +
                "is making me sick",
            },
            {
              label: "verse",
              text:
                "Can't let anything go\n" +
                "How will I know\n" +
                "if my corroding soul\n" +
                "won't break\n" +
                "in one precise blow",
            },
            {
              label: "verse",
              text:
                "I shake my head\n" +
                "Tell myself I'm silly\n" +
                "But at times I can't shake\n" +
                "this feeling within me",
            },
            {
              label: "verse",
              text:
                "Can't let anything go\n" +
                "How will I know\n" +
                "if my corroding soul\n" +
                "won't break\n" +
                "in one precise blow",
            },
            {
              label: "verse",
              text:
                "Can't let anything go\n" +
                "How will I know\n" +
                "if my corroding soul\n" +
                "won't break\n" +
                "in one precise blow",
            },
          ],
        },
        {
          title: "All I Want",
          length: "3:45",
          id: "wide-open-all-i-want",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "I would like to be the one\n" +
                "to dry away your tears\n" +
                "I would like to be one\n" +
                "to chase away your fears\n" +
                "Reaching out your hand\n" +
                "is all you have to do\n" +
                "I can be most anything\n" +
                "that you would like me to",
            },
            {
              label: "verse",
              text:
                "I would like to be the one\n" +
                "who makes you moan at night\n" +
                "I would like to be the one\n" +
                "to always hold you tight\n" +
                "I could be the light\n" +
                "that guides you through the dark\n" +
                "And the one to hold your hand\n" +
                "when strolling in the park",
            },
            {
              label: "verse",
              text:
                "All I want from you\n" +
                "is your loving to be true\n" +
                "if I seem demanding I apologise\n" +
                "I just want to see you love for me\n" +
                "in those beautiful eyes",
            },
            {
              label: "verse",
              text:
                "I would like to be the one\n" +
                "you watch across the room\n" +
                "I would like to be the one\n" +
                "who knows well your perfume\n" +
                "When you kiss my lips\n" +
                "I know that I'm alive\n" +
                "So my love I'd be the one\n" +
                "to see that you survive",
            },
            {
              label: "verse",
              text:
                "All I want from you\n" +
                "is your loving to be true\n" +
                "if I seem demanding I apologise\n" +
                "I just want to see you love for me\n" +
                "in those beautiful eyes",
            },
            {
              label: "verse",
              text:
                "All I want from you\n" +
                "is your loving to be true\n" +
                "if I seem demanding I apologise\n" +
                "I just want to see you love for me\n" +
                "in those beautiful eyes\n" +
                "I just want to see you love for me\n" +
                "in those beautiful eyes",
            },
          ],
        },
        {
          title: "Face the Child",
          length: "4:14",
          id: "wide-open-face-the-child",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "She can't leave your side\n" +
                "in fear of coming back\n" +
                "While you're gambling\n" +
                "she's trying to keep track",
            },
            {
              label: "verse",
              text:
                "You took her on a journey\n" +
                "One without return\n" +
                "Went as companions\n" +
                "She followed every turn",
            },
            {
              label: "verse",
              text:
                "Turn your head\n" +
                "Face the child\n" +
                "Ask yourself\n" +
                "who's keeping you alive\n" +
                "Don't give up\n" +
                "Don't forget to fight\n" +
                "'Cause you will\n" +
                "you'll be missed tonight",
            },
            {
              label: "verse",
              text:
                "Now you're gonna leave her\n" +
                "'cause you don't care\n" +
                "about your actions\n" +
                "though they're keeping you here",
            },
            {
              label: "verse",
              text:
                "Turn your head\n" +
                "Face the child\n" +
                "Ask yourself\n" +
                "who's keeping you alive\n" +
                "Don't give up\n" +
                "Don't forget to fight\n" +
                "'Cause you will\n" +
                "you'll be missed tonight",
            },
            {
              label: "verse",
              text:
                "Turn your head\n" +
                "Face the child\n" +
                "Ask yourself\n" +
                "who's keeping you alive\n" +
                "Don't give up\n" +
                "Don't forget to fight\n" +
                "'Cause you will\n" +
                "you'll be missed tonight",
            },
          ],
        },
        {
          title: "More and More",
          length: "4:56",
          id: "wide-open-more-and-more",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "I don't want love\n" +
                "'cause love is cruel\n" +
                "I don't want love \n" +
                "But I want you and I'm a fool",
            },
            {
              label: "verse",
              text:
                "I don't want love\n" +
                "'cause love is war\n" +
                "I don't want love\n" +
                "But I want you more an more",
            },
            {
              label: "verse",
              text:
                "I live on love\n" +
                "Cruel painful love war\n" +
                "Lasting is my love chore\n" +
                "Always wanting\n" +
                "more and more",
            },
            {
              label: "verse",
              text:
                "I don't want love\n" +
                "'cause love is pain\n" +
                "I don't want love\n" +
                "But I guess no pain releases no gain",
            },
            {
              label: "verse",
              text:
                "Love is hurtful\n" +
                "'cause love is spite\n" +
                "Love is hurtful\n" +
                "But I love you and I'm alright",
            },
            {
              label: "verse",
              text:
                "I live on love\n" +
                "Cruel painful love war\n" +
                "Lasting is my love chore\n" +
                "Always wanting\n" +
                "more and more",
            },
            {
              label: "verse",
              text:
                "I live on love\n" +
                "Cruel painful love war\n" +
                "Lasting is my love chore\n" +
                "Always wanting\n" +
                "more and more\n" +
                "more and more",
            },
          ],
        },
        {
          title: "I'm Too Happy",
          length: "4:17",
          id: "wide-open-im-too-happy",
          lyricsBy: "Lene Drachmann",
          musicBy: "Lene Drachmann",
          lyrics: [
            {
              label: "verse",
              text:
                "I'm too happy to be depressed\n" +
                "for artistic suffering and all the rest\n" +
                "I try to spill out my weary heart\n" +
                "But you make me smile right from the start\n" +
                "Yes, you make me smile right from the start",
            },
            {
              label: "verse",
              text:
                "I'll never make it\n" +
                "as the struggling artist\n" +
                "as long as I love you\n" +
                "and you'll love me back\n" +
                "No I'll never make it\n" +
                "as the struggling artist\n" +
                "as long as I love you\n" +
                "and you'll love me back",
            },
            {
              label: "verse",
              text:
                "I focus on every day's little things\n" +
                "I try to give life to the little things\n" +
                "But large- scale emotion is what I do\n" +
                "And it all comes down to loving you\n" +
                "Yes, it all comes down to loving you",
            },
            {
              label: "verse",
              text:
                "I'll never make it\n" +
                "as the struggling artist\n" +
                "as long as I love you\n" +
                "and you'll love me back\n" +
                "No I'll never make it\n" +
                "as the struggling artist\n" +
                "as long as I love you\n" +
                "and you'll love me back",
            },
            {
              label: "verse",
              text:
                "Honey oh honey I love you so\n" +
                "It's not like I will make you go\n" +
                "Sure a writer's block is bad\n" +
                "But life without you would be ten times as sad\n" +
                "Yes, life without you would be ten times as sad",
            },
            {
              label: "verse",
              text:
                "I'll never make it\n" +
                "as the struggling artist\n" +
                "as long as I love you\n" +
                "and you'll love me back\n" +
                "No I'll never make it\n" +
                "as the struggling artist\n" +
                "as long as I love you\n" +
                "and you'll love me back",
            },
            {
              label: "verse",
              text:
                "I'll never make it\n" +
                "as the struggling artist\n" +
                "as long as I love you\n" +
                "and you'll love me back\n" +
                "No I'll never make it\n" +
                "as the struggling artist\n" +
                "as long as I love you\n" +
                "and you'll love me back\n" +
                "and you'll love me back\n" +
                "and you'll love me back",
            },
          ],
        },
      ],
      publisher: "Tutl Records",
      roles: [
        "composer",
        "vocals",
        "drums",
        "bass",
        "guitar",
        "synthesizer",
        "piano",
        "producer",
      ],
      img: "/wide-open.jpg",
      id: "wide-open-2008",
    },
    {
      title: "Dødens Venteværelse",
      description: "",
      year: 2007,
      artist: "Ratslør",
      type: "album",
      listenOn: {
        soundcloud:
          "https://soundcloud.com/hans-bruun/ratslor-dodens-ventevaerelse?in=hans-bruun%2Fsets%2Fratslor-dodens-ventevaerelse",
      },
      songs: [
        {
          title: "Brevet fra Kursk",
          length: "4:09",
          id: "doedens-ventevaerelse-brevet-fra-kursk",
        },
      ],
      publisher: "In The House Records",
      roles: ["backing vocals"],
      img: "/ratsloer-doedens-ventevaerelse.jpg",
      id: "ratsloer-doedens-ventevarelse-2007",
      isNotPersonalProject: true,
    },
    {
      title: "DP Laurens - EP",
      description: "",
      year: 2007,
      artist: "DP Laurens",
      type: "ep",
      listenOn: {
        spotify:
          "https://open.spotify.com/album/2YUATjT3tR78ZqFI3BRWBJ?si=ouWsn6B9R8CoYepM8XZhFg",
        appleMusic: "https://itunes.apple.com/dk/album/dp-laurens-ep/571560657",
      },
      songs: [
        {
          title: "En Håndfuld Kærlighed",
          length: "4:52",
          id: "dp-laurens-ep-en-haandfuld-kaerlighed",
        },
      ],
      publisher: "Exlibris",
      roles: ["vocals"],
      img: "/dp-laurens-ep.jpg",
      id: "dp-laurens-ep-2007",
      isNotPersonalProject: true,
    },
    {
      title: "Live",
      description: "",
      year: 2006,
      artist: "Maria Guttesen",
      type: "album",
      listenOn: {
        spotify:
          "https://open.spotify.com/album/6sPOz4G8E1Lo2kLwiTpwMD?si=rBnRaeCJQfeqLUZfZcgIBg",
        appleMusic: "https://itunes.apple.com/dk/album/live/719559875",
      },
      songs: [
        {
          title: "Fear of the Dark (Live)",
          length: "5:18",
          id: "live-fear-of-the-dark-live",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "If you had a choice\n" +
                "would you know what to pick\n" +
                "Imagine you had a voice \n" +
                "facing a lunatic",
            },
            {
              label: "verse",
              text:
                "I'm walking faster\n" +
                "My pace is frantic\n" +
                "fear of darkness\n" +
                "is making me sick",
            },
            {
              label: "verse",
              text:
                "Can't let anything go\n" +
                "How will I know\n" +
                "if my corroding soul\n" +
                "won't break\n" +
                "in one precise blow",
            },
            {
              label: "verse",
              text:
                "I shake my head\n" +
                "Tell myself I'm silly\n" +
                "But at times I can't shake\n" +
                "this feeling within me",
            },
            {
              label: "verse",
              text:
                "Can't let anything go\n" +
                "How will I know\n" +
                "if my corroding soul\n" +
                "won't break\n" +
                "in one precise blow",
            },
            {
              label: "verse",
              text:
                "Can't let anything go\n" +
                "How will I know\n" +
                "if my corroding soul\n" +
                "won't break\n" +
                "in one precise blow",
            },
          ],
        },
        {
          title: "Luckily (Live)",
          length: "4:33",
          id: "live-luckily-live",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen & Stephen Nørrelykke",
          lyrics: [
            {
              label: "verse",
              text: "Priority\n" + "Will you decide\n" + "Who it's going to be",
            },
            {
              label: "verse",
              text:
                "Honestly\n" + "I'd waste 10 lives\n" + "for one of quality",
            },
            {
              label: "verse",
              text:
                "Luckily I'm not God\n" +
                "I can go on living\n" +
                "Without a thought\n" +
                "Luckily it's allowed\n" +
                "to take the easy road\n" +
                "and rest in a cloud",
            },
            {
              label: "verse",
              text:
                "Unfortunately\n" + "Even with covered eyes\n" + "I can see",
            },
            {
              label: "verse",
              text:
                "Between you and me\n" +
                "Would you decide\n" +
                "Who it's going to be",
            },
            {
              label: "verse",
              text:
                "Luckily I'm not God\n" +
                "I can go on living\n" +
                "Without a thought\n" +
                "Luckily it's allowed\n" +
                "to take the easy road\n" +
                "and rest in a cloud",
            },
            {
              label: "verse",
              text:
                "Luckily I'm not God\n" +
                "I can go on living\n" +
                "Without a thought\n" +
                "Luckily it's allowed\n" +
                "to take the easy road\n" +
                "and rest in a cloud",
            },
            {
              label: "verse",
              text:
                "Between you and me\n" +
                "Would you decide\n" +
                "Who it's going to be",
            },
          ],
        },
        {
          title: "Magic to Be Two (Live)",
          length: "5:33",
          id: "live-magic-to-be-two-live",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "When I try to write\n" +
                "Get out words of meaning\n" +
                "My pen runs out of ink\n" +
                "I speak without feeling\n" +
                "Until you sit at the piano\n" +
                "With a concentrated smile\n" +
                "I remember my soul\n" +
                "And it's all worth while",
            },
            {
              label: "verse",
              text:
                "Hearing you sing\n" +
                "Things that I have inside\n" +
                "This new dimension\n" +
                "Makes even the bad alright\n" +
                "baby you sit at the piano\n" +
                "sending me sporadic smiles\n" +
                "You are music in me\n" +
                "My love from the isles",
            },
            {
              label: "verse",
              text:
                "I've been just myself for so long\n" +
                "No warm heart to channel through\n" +
                "No inspiration for poetry\n" +
                "What magic to be two",
            },
            {
              label: "verse",
              text:
                "Singing lips I kiss\n" +
                "every time I think\n" +
                "Think in lines of rhyme\n" +
                "you and me in perfect sync\n" +
                "And you sit at the piano\n" +
                "I write by the light of your smile\n" +
                "Creating together\n" +
                "The song is our child",
            },
            {
              label: "verse",
              text:
                "I've been just myself for so long\n" +
                "No warm heart to channel through\n" +
                "No inspiration for poetry\n" +
                "What magic to be two",
            },
            {
              label: "verse",
              text:
                "I've been just myself for so long\n" +
                "No warm heart to channel through\n" +
                "No inspiration for poetry\n" +
                "What magic to be two",
            },
          ],
        },
        {
          title: "I'm Too Happy (Live)",
          length: "5:15",
          id: "live-im-too-happy-live",
          lyricsBy: "Lene Drachmann",
          musicBy: "Lene Drachmann",
          lyrics: [
            {
              label: "verse",
              text:
                "I'm too happy to be depressed\n" +
                "for artistic suffering and all the rest\n" +
                "I try to spill out my weary heart\n" +
                "But you make me smile right from the start\n" +
                "Yes, you make me smile right from the start",
            },
            {
              label: "verse",
              text:
                "I'll never make it\n" +
                "as the struggling artist\n" +
                "as long as I love you\n" +
                "and you'll love me back\n" +
                "No I'll never make it\n" +
                "as the struggling artist\n" +
                "as long as I love you\n" +
                "and you'll love me back",
            },
            {
              label: "verse",
              text:
                "I focus on every day's little things\n" +
                "I try to give life to the little things\n" +
                "But large- scale emotion is what I do\n" +
                "And it all comes down to loving you\n" +
                "Yes, it all comes down to loving you",
            },
            {
              label: "verse",
              text:
                "I'll never make it\n" +
                "as the struggling artist\n" +
                "as long as I love you\n" +
                "and you'll love me back\n" +
                "No I'll never make it\n" +
                "as the struggling artist\n" +
                "as long as I love you\n" +
                "and you'll love me back",
            },
            {
              label: "verse",
              text:
                "Honey oh honey I love you so\n" +
                "It's not like I will make you go\n" +
                "Sure a writer's block is bad\n" +
                "But life without you would be ten times as sad\n" +
                "Yes, life without you would be ten times as sad",
            },
            {
              label: "verse",
              text:
                "I'll never make it\n" +
                "as the struggling artist\n" +
                "as long as I love you\n" +
                "and you'll love me back\n" +
                "No I'll never make it\n" +
                "as the struggling artist\n" +
                "as long as I love you\n" +
                "and you'll love me back",
            },
            {
              label: "verse",
              text:
                "I'll never make it\n" +
                "as the struggling artist\n" +
                "as long as I love you\n" +
                "and you'll love me back\n" +
                "No I'll never make it\n" +
                "as the struggling artist\n" +
                "as long as I love you\n" +
                "and you'll love me back\n" +
                "and you'll love me back\n" +
                "and you'll love me back",
            },
          ],
        },
        {
          title: "I Wish (Live)",
          length: "3:53",
          id: "live-i-wish-live",
          lyricsBy: "Lene Drachmann",
          musicBy: "Lene Drachmann",
          lyrics: [
            {
              label: "verse",
              text:
                "This small room\n" +
                "Golden with smoke and age\n" +
                "Sweat and perfume\n" +
                "Caging love and rage",
            },
            {
              label: "verse",
              text:
                "A small cloud\n" +
                "lined with gold and cream\n" +
                "Loved by the crowd\n" +
                "Muting my scream",
            },
            {
              label: "verse",
              text:
                "Wish my ears couldn't hear\n" +
                "Wish I had nothing to fear\n" +
                "I cover my mouth and pray\n" +
                "Wish I had nothing to say",
            },
            {
              label: "verse",
              text:
                "I'm all right\n" +
                "Well that's what I tell you\n" +
                "Loosing the light\n" +
                "The day is through",
            },
            {
              label: "verse",
              text:
                "Wish my ears couldn't hear\n" +
                "Wish I had nothing to fear\n" +
                "I cover my mouth and pray\n" +
                "Wish I had nothing to say",
            },
          ],
        },
        {
          title: "Divided Minds (Live)",
          length: "5:03",
          id: "live-divided-minds-live",
          lyricsBy: "Maria Salomon",
          musicBy: "Maria Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "I've been to long\n" +
                "on lonely streets\n" +
                "seen so much misery\n" +
                "enough sorrow and fear\n" +
                "reflects my mind - uhh\n" +
                "my mind",
            },
            {
              label: "verse",
              text:
                "We can't guide \n" +
                "our problems into\n" +
                "into something unreal\n" +
                "we can't hide\n" +
                "hide in a world\n" +
                "that's trembling",
            },
            {
              label: "verse",
              text:
                "Divided Minds\n" +
                "in restless bodies\n" +
                "just small people\n" +
                "in a lonely world\n" +
                "just small people \n" +
                "in a lonely world",
            },
            {
              label: "verse",
              text:
                "I know where\n" +
                "I can preserve my\n" +
                "my agony my faults\n" +
                "I do know\n" +
                "where to search\n" +
                "shift and task\n" +
                "shift and task",
            },
            {
              label: "verse",
              text:
                "Divided Minds\n" +
                "in restless bodies\n" +
                "just small people\n" +
                "in a lonely world\n" +
                "just small people \n" +
                "in a lonely world",
            },
            {
              label: "verse",
              text:
                "Divided Minds\n" +
                "in restless bodies\n" +
                "just small people\n" +
                "in a lonely world\n" +
                "just small people \n" +
                "in a lonely world",
            },
          ],
        },
        {
          title: "One Sorry Will Do (Live)",
          length: "3:52",
          id: "live-one-sorry-will-do-live",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "Couldn't sleep\n" +
                "Thinking of you\n" +
                "I wouldn't fight\n" +
                "Like you wanted me to",
            },
            {
              label: "verse",
              text:
                "Threw a fit\n" +
                "Slinging your mud\n" +
                "Tried to forgive\n" +
                "My own flesh and blood",
            },
            {
              label: "verse",
              text:
                "Don't take for granted\n" +
                "That I will be there\n" +
                "Maybe one day\n" +
                "I really won't care",
            },
            {
              label: "verse",
              text:
                "Don't you know\n" +
                "You're doing wrong\n" +
                "Don't depend on me\n" +
                "Singing your song",
            },
            {
              label: "verse",
              text:
                "Don't take for granted\n" +
                "That I will be there\n" +
                "Maybe one day\n" +
                "I really won't care",
            },
            {
              label: "verse",
              text:
                "Sister, one sorry will do\n" +
                "Put your pride aside\n" +
                "And mend my broken heart\n" +
                "Sister, one sorry will do\n" +
                "Be a little smart\n" +
                "Before we drift apart",
            },
            {
              label: "verse",
              text:
                "Don't take for granted\n" +
                "That I will be there\n" +
                "Maybe one day\n" +
                "I really won't care\n" +
                "Maybe one day\n" +
                "I really won't care\n" +
                "Maybe one day\n" +
                "I really won't care",
            },
            {
              label: "verse",
              text:
                "Maybe one day\n" +
                "I really won't care, won't care\n" +
                "Maybe one day\n" +
                "I really won't care, won't care\n" +
                "Maybe one day\n" +
                "I really won't care, won't care",
            },
          ],
        },
        {
          title: "You Keep Me Sane (Live)",
          length: "5:36",
          id: "live-you-keep-me-sane-live",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "It's six am\n" +
                "I still haven't slept\n" +
                "I am loosing track\n" +
                "of the time that I kept",
            },
            {
              label: "verse",
              text:
                "Pain in my head\n" +
                "Limbs aching\n" +
                "Most of the trouble\n" +
                "I myself am making",
            },
            {
              label: "verse",
              text:
                "I'm putting the world upon my own sholders\n" +
                "I'm taking junk that I didn't define\n" +
                "I'm liftin a burden I shouldn't be given\n" +
                "I'm picking up pieces that were never mine",
            },
            {
              label: "verse",
              text:
                "When night time is dark\n" +
                "You keep me sane\n" +
                "Your holding the light\n" +
                "Whispering my name",
            },
            {
              label: "verse",
              text:
                "I'm putting the world upon my own shoulders\n" +
                "I'm taking junk that I didn't define\n" +
                "I'm lifting a burden I shouldn't be given\n" +
                "I'm picking up pieces that were never mine",
            },
            {
              label: "verse",
              text:
                "The pressure is rising I have to say stop\n" +
                "if you hold my hand I think I can get up\n" +
                "The pressure is rising I have to say no\n" +
                "if you hold my hand I think I can let it all go",
            },
            {
              label: "verse",
              text:
                "I'm putting the world upon my own shoulders\n" +
                "I'm taking junk that I didn't define\n" +
                "I'm lifting a burden I shouldn't be given\n" +
                "I'm picking up pieces that were never mine",
            },
          ],
        },
        {
          title: "Frá Mammu (Live)",
          length: "6:09",
          id: "live-fra-mammu-live",
          lyricsBy: "Elspa Metzdorff",
          musicBy: "Maria Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "Lítli skattur, nú seg nærkar nátt.\n" +
                "Stjørnurnar á himli lýsa flott.\n" +
                "Kom balla teg nú undir dýnuna\n" +
                "og lat eygu tíni aftur brátt.\n" +
                "Tú altíð mínun hjarta stendur nær\n" +
                "og eg skal lova tær,\n" +
                "at hvør tú enn er og hvat tú enn ger,\n" +
                "eg altíð vera við hjá tær",
            },
            {
              label: "verse",
              text:
                "Tú sveimar skjótt inn í dreymaverð.\n" +
                "Tú morgindagin á hondum ber.\n" +
                "Eg vóni at tú finnur rætta leið.\n" +
                "At tað kennist trygt á tíni ferð.\n" +
                "Tú lova mást, at tú ikki gloymir,\n" +
                "At heimi títt er her,\n" +
                "at hvar tú enn er og hvat tú enn gerð,\n" +
                "tú okkum tína heilsu ber",
            },
            {
              label: "verse",
              text:
                "Lítli skattur nú er komin nátt.\n" +
                "Stjørnurnar á himli lýsa flott.\n" +
                "Tú ert í dreymaverð.\n" +
                "Í morgin aftur dagur er.\n" +
                "Vit goyma minnini um henda dag sum fer",
            },
            {
              label: "verse",
              text:
                "Tú var í dreymaverð.\n" +
                "Morgindagur komin er\n" +
                "og minnini tú í hjatra tínum ber",
            },
          ],
        },
        {
          title: "Time Keeper (Live)",
          length: "4:15",
          id: "live-time-keeper-live",
          lyricsBy: "Maria Salomon",
          musicBy: "Maria Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "Sometimes I find it hard to keep up\n" +
                "When time gets out of my control\n" +
                "When my thoughts are far away\n" +
                "Where illusions become reality",
            },
            {
              label: "verse",
              text:
                "Where do we go from here\n" +
                "Walking on the edge of time\n" +
                "I'm the time keeper\n" +
                "I'm the time keeper",
            },
            {
              label: "verse",
              text:
                "Fragments of yesterday's doings\n" +
                "It all seems so unorganized\n" +
                "Time is just a ticking bomb\n" +
                "My nerves are strained to the breaking point",
            },
            {
              label: "verse",
              text:
                "Where do we go from here\n" +
                "Walking on the edge of time\n" +
                "Where do we go from here\n" +
                "Walking on the edge of time\n" +
                "I'm the time keeper\n" +
                "I'm the time keeper",
            },
          ],
        },
        {
          title: "8 Half Minutes (Live)",
          length: "3:41",
          id: "live-8-half-minutes-live",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen & Stephen Nørrelykke",
          lyrics: [
            {
              label: "verse",
              text:
                "One two three\n" +
                "Why won't you see\n" +
                "That what I say\n" +
                "Is truth to me",
            },
            {
              label: "verse",
              text:
                "Four five six\n" +
                "I never mix\n" +
                "The hones face\n" +
                "With scamming tricks",
            },
            {
              label: "verse",
              text:
                "Scars and scratches\n" +
                "I can do retouches\n" +
                "But playing with matches\n" +
                "won't keep you dry \n" +
                "Your eyes water up\n" +
                "when you cry",
            },
            {
              label: "verse",
              text:
                "Seven eight\n" +
                "At this rate\n" +
                "This trustless race\n" +
                "A clingy mate",
            },
            {
              label: "verse",
              text:
                "Scars and scratches\n" +
                "I can do retouches\n" +
                "But playing with matches\n" +
                "won't keep you dry \n" +
                "Your eyes water up\n" +
                "when you cry",
            },
            {
              label: "verse",
              text:
                "Scars and scratches\n" +
                "I can do retouches\n" +
                "But playing with matches\n" +
                "won't keep you dry \n" +
                "Your eyes water up\n" +
                "when you cry",
            },
          ],
        },
        {
          title: "Another Try (Live)",
          length: "5:09",
          id: "live-another-try-live",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "I cry\n" +
                "'Cause I'm lost in the sky\n" +
                "I didn't really wanna die\n" +
                "I'd like one more try",
            },
            {
              label: "verse",
              text:
                "I pray\n" +
                "Let me out of this ray\n" +
                "I didn't want it anyway\n" +
                "I'll go back and stay",
            },
            {
              label: "verse",
              text:
                "I see now\n" +
                "that life is worth living\n" +
                "I see now\n" +
                "little treasure so giving\n" +
                "I hear now\n" +
                "the future is calling\n" +
                "The future is calling now",
            },
            {
              label: "verse",
              text:
                "I fell\n" +
                "I had landed in hell\n" +
                "I really wasn't well\n" +
                "and noone could tell",
            },
            {
              label: "verse",
              text:
                "But here\n" +
                "I feel nothing but fear\n" +
                "it's all becomming clear\n" +
                "Please make it disappear",
            },
            {
              label: "verse",
              text:
                "I see now\n" +
                "that life is worth living\n" +
                "I see now\n" +
                "little treasure so giving\n" +
                "I hear now\n" +
                "the future is calling\n" +
                "The future is calling now",
            },
            {
              label: "verse",
              text:
                "Please take all my promises\n" +
                "and grant another wish\n" +
                "I would have looked more serious\n" +
                "if I really wanted this\n" +
                "I won't make believe in it\n" +
                "can't play tricks on fate\n" +
                "I'll live my life so splendidly\n" +
                "I hope it's not to late",
            },
            {
              label: "verse",
              text:
                "I see now\n" +
                "that life is worth living\n" +
                "I see now\n" +
                "little treasure so giving\n" +
                "I hear now\n" +
                "the future is calling\n" +
                "The future is calling now",
            },
            {
              label: "verse",
              text:
                "I see now\n" +
                "that life is worth living\n" +
                "I see now\n" +
                "little treasure so giving\n" +
                "I hear now\n" +
                "the future is calling\n" +
                "The future is calling now",
            },
          ],
        },
      ],
      publisher: "Tutl Records",
      roles: ["vocals", "piano"],
      img: "/live.jpg",
      id: "live-2006",
    },
    {
      title: "The Last Night",
      description: "",
      year: 2004,
      artist: "Divided Minds",
      type: "album",
      listenOn: {
        spotify:
          "https://open.spotify.com/album/4uJF77KmEp8WorVqiLs0tn?si=HThM5F7bRfWipYptcbK7KA",
        appleMusic:
          "https://itunes.apple.com/us/album/the-last-night/151950487",
      },
      songs: [
        {
          title: "Caught by You",
          length: "4:18",
          id: "the-last-night-caught-by-you",
          lyricsBy: "Lene Drachmann",
          musicBy: "Stephen Nørrelykke",
          lyrics: [
            {
              label: "verse",
              text:
                "Caught By You\n" +
                "I've fallen deep into Baba's hole\n" +
                "it's a risky venture\n" +
                "That you control",
            },
            {
              label: "verse",
              text:
                "I am here\n" +
                "Careless with my nudity\n" +
                "Throwing down my fences\n" +
                "For you to see",
            },
            {
              label: "verse",
              text:
                "It's because the sun is shining all the time\n" +
                "And my skin is scented by the shiny sun\n" +
                "In a sense it's like you're always here with me\n" +
                "Love is catchy\n" +
                "- And just begun",
            },
            {
              label: "verse",
              text:
                "So hung up\n" +
                "You're cramming things to be with me\n" +
                "I don't mind the waiting\n" +
                "The simplicity",
            },
            {
              label: "verse",
              text:
                "It's because the sun is shining all the time\n" +
                "And my skin is scented by the shiny sun\n" +
                "In a sense it's like you're always here with me\n" +
                "Love is catchy\n" +
                "- And just begun",
            },
            {
              label: "verse",
              text:
                "It's because the sun is shining all the time\n" +
                "And my skin is scented by the shiny sun\n" +
                "In a sense it's like you're always here with me\n" +
                "Love is catchy\n" +
                "- And just begun",
            },
          ],
        },
        {
          title: "I'm Not Stronger",
          length: "3:45",
          id: "the-last-night-im-not-stronger",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "You don't think about my future\n" +
                "About the gab I'm heading at\n" +
                "You don't consider my partner\n" +
                "You don't think we're all that",
            },
            {
              label: "verse",
              text:
                "You don't see that my earth shakes\n" +
                "When your own carries on\n" +
                "You don't think that my back breaks\n" +
                "If you're own back is strong",
            },
            {
              label: "chorus",
              text:
                "\"You'll be fine you say,\" You'll get through\n" +
                '"You will cope you say," You always do\n' +
                "But sometimes I'm down\n" +
                "And I'm not at all stronger than you",
            },
            {
              label: "verse",
              text:
                "I can't count on the strength\n" +
                "That you all think I posses\n" +
                "I can't count on any force\n" +
                "to lead me to success",
            },
            {
              label: "chorus",
              text:
                "\"You'll be fine you say,\" You'll get through\n" +
                '"You will cope you say," You always do\n' +
                "But sometimes I'm down\n" +
                "And I'm not at all stronger than you",
            },
            {
              label: "verse",
              text:
                "You think weakness is annoying\n" +
                "And sickness is a bore\n" +
                "But I don't need you pep talk\n" +
                "I need your comfort so much more",
            },
            {
              label: "chorus",
              text:
                "\"You'll be fine you say,\" You'll get through\n" +
                '"You will cope you say," You always do\n' +
                "But sometimes I'm down\n" +
                "And I'm not at all stronger than you",
            },
            {
              label: "outro",
              text:
                "And I'm not at all stronger than you\n" +
                "And I'm not at all stronger than you",
            },
          ],
        },
        {
          title: "Before I Met You",
          length: "3:52",
          id: "the-last-night-before-i-met-you",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen & Stephen Nørrelykke",
          lyrics: [
            {
              label: "verse",
              text:
                "Before I met you, before I met you\n" +
                "I had no roots, no place to rest my tired head\n" +
                "Before you came, I had a thunder inside\n" +
                "looking for shelter, but being stripped instead",
            },
            {
              label: "verse",
              text:
                "But there you were\n" +
                "Insisting to be near\n" +
                "You had a magical presence\n" +
                "Warm and sincere",
            },
            {
              label: "verse",
              text:
                "Before I met you, before I met you\n" +
                "I fooled around, making a fool out of me\n" +
                "Before you came, I was in shambles\n" +
                "My heart and my soul seemed to disagree",
            },
            {
              label: "verse",
              text:
                "But there you were\n" +
                "Insisting to be near\n" +
                "You had a magical presence\n" +
                "Warm and sincere",
            },
            {
              label: "verse",
              text:
                "It's like I just woke\n" +
                "From a gentle loving poke\n" +
                "Into me you grow \n" +
                "telling me what I need to know",
            },
            {
              label: "verse",
              text:
                "Before I met you, before I met you\n" +
                "I was trapped, I was sitting on a trap door\n" +
                "before you came, I was just waiting\n" +
                "never really knowing what to look for",
            },
            {
              label: "verse",
              text:
                "But there you were\n" +
                "Insisting to be near\n" +
                "You had a magical presence\n" +
                "Warm and sincere",
            },
          ],
        },
        {
          title: "Another Try",
          length: "3:57",
          id: "the-last-night-another-try",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "I cry\n" +
                "'Cause I'm lost in the sky\n" +
                "I didn't really wanna die\n" +
                "I'd like one more try",
            },
            {
              label: "verse",
              text:
                "I pray\n" +
                "Let me out of this ray\n" +
                "I didn't want it anyway\n" +
                "I'll go back and stay",
            },
            {
              label: "verse",
              text:
                "I see now\n" +
                "that life is worth living\n" +
                "I see now\n" +
                "little treasure so giving\n" +
                "I hear now\n" +
                "the future is calling\n" +
                "The future is calling now",
            },
            {
              label: "verse",
              text:
                "I fell\n" +
                "I had landed in hell\n" +
                "I really wasn't well\n" +
                "and noone could tell",
            },
            {
              label: "verse",
              text:
                "But here\n" +
                "I feel nothing but fear\n" +
                "it's all becomming clear\n" +
                "Please make it disappear",
            },
            {
              label: "verse",
              text:
                "I see now\n" +
                "that life is worth living\n" +
                "I see now\n" +
                "little treasure so giving\n" +
                "I hear now\n" +
                "the future is calling\n" +
                "The future is calling now",
            },
            {
              label: "verse",
              text:
                "Please take all my promises\n" +
                "and grant another wish\n" +
                "I would have looked more serious\n" +
                "if I really wanted this\n" +
                "I won't make believe in it\n" +
                "can't play tricks on fate\n" +
                "I'll live my life so splendidly\n" +
                "I hope it's not to late",
            },
            {
              label: "verse",
              text:
                "I see now\n" +
                "that life is worth living\n" +
                "I see now\n" +
                "little treasure so giving\n" +
                "I hear now\n" +
                "the future is calling\n" +
                "The future is calling now",
            },
            {
              label: "verse",
              text:
                "I see now\n" +
                "that life is worth living\n" +
                "I see now\n" +
                "little treasure so giving\n" +
                "I hear now\n" +
                "the future is calling\n" +
                "The future is calling now",
            },
          ],
        },
        {
          title: "A Better Today",
          length: "5:01",
          id: "the-last-night-a-better-today",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "Got a letter from a long lost friend\n" +
                "Nothing to recover, nothing to defend\n" +
                "A touch of warmth that I once felt\n" +
                "Run the emotional conveyer belt",
            },
            {
              label: "verse",
              text:
                "Forgive and forget and swallow the rest\n" +
                "I bury the corpses inside my chest\n" +
                "Time will heal 'cause I won't remember \n" +
                "The grudge will die like a tiny ember",
            },
            {
              label: "verse",
              text:
                "I can only make tomorrow a better today\n" +
                "I can only make tomorrow a better today\n" +
                "I can only make tomorrow a better today\n" +
                "I can only make tomorrow a better today",
            },
            {
              label: "verse",
              text:
                "Too many times I've been a temptress\n" +
                "Hoping the rejection was only lies\n" +
                "So much love with no one to bless\n" +
                "The hardened soul was just a disguise",
            },
            {
              label: "verse",
              text:
                "I can only make tomorrow a better today\n" +
                "I can only make tomorrow a better today\n" +
                "I can only make tomorrow a better today\n" +
                "I can only make tomorrow a better today",
            },
            {
              label: "verse",
              text:
                "I can only make tomorrow a better today\n" +
                "I can only make tomorrow a better today",
            },
          ],
        },
        {
          title: "Amazing Girl",
          length: "4:13",
          id: "the-last-night-amazing-girl",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "Dancing around\n" +
                "Simply amazing\n" +
                "Being a clown\n" +
                "Just entertaining",
            },
            {
              label: "verse",
              text:
                "Caring for all\n" +
                "Just like a grown-up\n" +
                "Warm like a child\n" +
                "So anti stuck up",
            },
            {
              label: "verse",
              text:
                "When life is hard\n" +
                "You're understanding\n" +
                "You are a rock\n" +
                "never demanding",
            },
            {
              label: "verse",
              text:
                "Amazing girl\n" +
                "you can cry with me and shout at me\n" +
                "you're always a treasure\n" +
                "Amazing girl\n" +
                "don't be strong with me\n" +
                "it's always a pleasure\n" +
                "to sit and talk or play with you\n" +
                "Amazing girl",
            },
            {
              label: "verse",
              text:
                "I'll never be far\n" +
                "just give me a call\n" +
                "I'll do my best to cushion your fal",
            },
            {
              label: "verse",
              text:
                "Don't be afraid\n" +
                "It's never too late\n" +
                "Your company\n" +
                "delightful for me",
            },
            {
              label: "verse",
              text:
                "Amazing girl\n" +
                "you can cry with me and shout at me\n" +
                "you're always a treasure\n" +
                "Amazing girl\n" +
                "don't be strong with me\n" +
                "it's always a pleasure\n" +
                "to sit and talk or play with you\n" +
                "Amazing girl",
            },
          ],
        },
        {
          title: "I Wish",
          length: "4:30",
          id: "the-last-night-i-wish",
          lyricsBy: "Lene Drachmann",
          musicBy: "Lene Drachmann",
          lyrics: [
            {
              label: "verse",
              text:
                "This small room\n" +
                "Golden with smoke and age\n" +
                "Sweat and perfume\n" +
                "Caging love and rage",
            },
            {
              label: "verse",
              text:
                "A small cloud\n" +
                "lined with gold and cream\n" +
                "Loved by the crowd\n" +
                "Muting my scream",
            },
            {
              label: "verse",
              text:
                "Wish my ears couldn't hear\n" +
                "Wish I had nothing to fear\n" +
                "I cover my mouth and pray\n" +
                "Wish I had nothing to say",
            },
            {
              label: "verse",
              text:
                "I'm all right\n" +
                "Well that's what I tell you\n" +
                "Loosing the light\n" +
                "The day is through",
            },
            {
              label: "verse",
              text:
                "Wish my ears couldn't hear\n" +
                "Wish I had nothing to fear\n" +
                "I cover my mouth and pray\n" +
                "Wish I had nothing to say",
            },
          ],
        },
        {
          title: "Come On",
          length: "3:39",
          id: "the-last-night-come-on",
          lyricsBy: "Lene Drachman",
          musicBy: "Maria Guttesen & Stephen Nørrelykke",
          lyrics: [
            {
              label: "verse",
              text:
                "You're trapped in a cast\n" +
                "your tradition's dated\n" +
                "Your ears stuffed with resistance\n" +
                "your eyesight faded\n" +
                "I don't say it right\n" +
                "my suggestions tripping\n" +
                "I only stuff your ears with more lully gripping",
            },
            {
              label: "verse",
              text:
                "Come on\n" +
                "I'm not playing\n" +
                "I'm playing\n" +
                "praying for progress\n" +
                "Stop defending\n" +
                "Start attending\n" +
                "The now",
            },
            {
              label: "verse",
              text:
                "Won't be your excuse\n" +
                "I am not to blame\n" +
                "though I intervened\n" +
                "You're still the same\n" +
                "So endlessly\n" +
                "hopelessly trend less",
            },
            {
              label: "verse",
              text: "Must keep in line\n" + "refusing redesign",
            },
            {
              label: "verse",
              text:
                "Come on\n" +
                "I'm not playing\n" +
                "I'm playing\n" +
                "praying for progress\n" +
                "Stop defending\n" +
                "Start attending\n" +
                "The now",
            },
            {
              label: "verse",
              text:
                "Putting a friend down\n" +
                "won't make your wrongs right\n" +
                "Take away that dumb frown\n" +
                "you really don't have to fight\n" +
                "I'm saying this in capital letters\n" +
                "I'm saying this only to make things better\n" +
                "Come on - Cool down\n" +
                "Listen to yourself - listen to the sad sound",
            },
            {
              label: "verse",
              text:
                "Come on\n" +
                "I'm not playing\n" +
                "I'm playing\n" +
                "praying for progress\n" +
                "Stop defending\n" +
                "Start attending\n" +
                "The now",
            },
            {
              label: "verse",
              text:
                "Come on\n" +
                "I'm not playing\n" +
                "I'm playing\n" +
                "praying for progress\n" +
                "Stop defending\n" +
                "Start attending\n" +
                "The now",
            },
          ],
        },
        {
          title: "Autumn Came Too Soon",
          length: "5:13",
          id: "the-last-night-autumn-came-too-soon",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "Tonight I sav the larges rainbow\n" +
                "Right in the dark blue sky\n" +
                "A moment there your memory left me\n" +
                "and I forgot to cry\n" +
                "I turned my head and fire clouds\n" +
                "would drift across my view\n" +
                "It's nice to know that nature's art\n" +
                "can stop me missing you",
            },
            {
              label: "verse",
              text:
                "I kept myself from falling for you\n" +
                "until you said you cared\n" +
                "Then I gave up the barricade\n" +
                "before you were prepared\n" +
                "Now I sit here all by myself\n" +
                "writing out my pain\n" +
                "The sky has no more rainbow shine\n" +
                "so all I see is rain",
            },
            {
              label: "verse",
              text:
                "I first met you in springtime\n" +
                "But didn't fall in love until june\n" +
                "I think I really touched something \n" +
                "inside me\n" +
                "and it's painful and it's painful\n" +
                "'cause you have gone and left me \n" +
                "far to soon",
            },
            {
              label: "verse",
              text:
                "All day I sat here thinking of you\n" +
                "let my tears run free\n" +
                "Knowing that I waste my time\n" +
                "on someone leaving me\n" +
                "But you have been so beautiful\n" +
                "and turned my wanting heart\n" +
                "I gave it to you willingly\n" +
                "and blame myself in part",
            },
            {
              label: "verse",
              text:
                "I first met you in springtime\n" +
                "But didn't fall in love until June\n" +
                "I think you really touched something \n" +
                "inside me\n" +
                "and it's painful and it's  painful\n" +
                "'cause you have gone and left me \n" +
                "far to soon",
            },
          ],
        },
        {
          title: "Your Eyes",
          length: "4:42",
          id: "the-last-night-your-eyes",
          lyricsBy: "Lene Drachmann",
          musicBy: "Stephen Nørrelykke",
          lyrics: [
            {
              label: "verse",
              text:
                "I had only been told about you\n" +
                "Stories about your smile\n" +
                "how you can treasure life\n" +
                "being warm and mild",
            },
            {
              label: "verse",
              text:
                "I walked in having no expectation\n" +
                "of wonderful quality\n" +
                "such rare openheartedness\n" +
                "you just showed to me",
            },
            {
              label: "verse",
              text:
                "When I first  sat down next to you\n" +
                "So frail you sat there\n" +
                "not an uneasy moment\n" +
                "feeling you tender air",
            },
            {
              label: "verse",
              text:
                "You owe me nothing\n" +
                "but gave me everything\n" +
                "with a glimpse into\n" +
                "your beautiful eyes",
            },
            {
              label: "verse",
              text:
                "My life is lacking your kind\n" +
                "Here in your life's December\n" +
                "But you don't need another child\n" +
                "Still - I'll remember",
            },
            {
              label: "verse",
              text:
                "You owe me nothing\n" +
                "(makes me want us to meet again)\n" +
                "but gave me everything\n" +
                "with a glimpse into\n" +
                "(makes me want to believe in heaven)\n" +
                "your beautiful eyes",
            },
            {
              label: "verse",
              text:
                "You owe me nothing\n" +
                "(makes me want us to meet again)\n" +
                "but gave me everything\n" +
                "with a glimpse into\n" +
                "(makes me want to believe in heaven)\n" +
                "your beautiful eyes",
            },
            {
              label: "verse",
              text:
                "I hope that we will meet again\n" +
                "We can have a really good talk then\n" +
                "They said you knew, said you knew\n" +
                "- just when",
            },
          ],
        },
        {
          title: "The Last Night",
          length: "7:22",
          id: "the-last-night-the-last-night",
          lyricsBy: "Lene Drachmann",
          musicBy: "Maria Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "When you are lying fast asleep\n" +
                "I pretend this is my day\n" +
                "I'm pretending that you care so much\n" +
                "that you will aways stay\n" +
                "'cause I really want you\n" +
                "That's why I feel so blue\n" +
                "I can't prevent the many things\n" +
                "That are pulling you away",
            },
            {
              label: "verse",
              text:
                "When you are singing love songs\n" +
                "I pretend it's me you see\n" +
                "I pretend the corny phrases mean\n" +
                "that we are meant to be\n" +
                "'cause I really want you\n" +
                "That's why I feel so blue\n" +
                "The words are meant to someone\n" +
                "but just not for me",
            },
            {
              label: "verse",
              text:
                "You are so beautiful\n" +
                "as you lie next to me so peacefully\n" +
                "You are so beautiful\n" +
                "As dreams set you free\n" +
                "I wish to be a part of you\n" +
                "but I have to let you be\n" +
                "'cause you are lost in fairy tales\n" +
                "far away from me",
            },
            {
              label: "verse",
              text:
                "When you send me shiny smiles\n" +
                "I'm pretending sunny weather\n" +
                "I'm pretending that your smiles fulfil\n" +
                "my dream of us together\n" +
                "'cause I really want you \n" +
                "That's why I feel so blue\n" +
                "Soon I'll sit in solitude, missing you forever",
            },
            {
              label: "verse",
              text:
                "You are so beautiful\n" +
                "as you lie next to me so peacefully\n" +
                "You are so beautiful\n" +
                "As dreams set you free\n" +
                "I wish to be a part of you\n" +
                "but I have to let you be\n" +
                "'cause you are lost in fairy tales\n" +
                "far away from me",
            },
            {
              label: "verse",
              text:
                "You are so beautiful\n" +
                "as you lie next to me so peacefully\n" +
                "You are so beautiful\n" +
                "As dreams set you free\n" +
                "I wish to be a part of you\n" +
                "but I have to let you be\n" +
                "'cause you are lost in fairy tales\n" +
                "far away from me",
            },
            {
              label: "verse",
              text:
                "You are so beautiful.... yeah yeah yeah yeah\n" +
                "You are so beautiful.... yeah yeah yeah",
            },
          ],
        },
        {
          title: "Time Keeper",
          length: "4:49",
          id: "the-last-night-time-keeper",
          lyricsBy: "Maria Salomon",
          musicBy: "Maria Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "Sometimes I find it hard to keep up\n" +
                "When time gets out of my control\n" +
                "When my thoughts are far away\n" +
                "Where illusions become reality",
            },
            {
              label: "verse",
              text:
                "Where do we go from here\n" +
                "Walking on the edge of time\n" +
                "I'm the time keeper\n" +
                "I'm the time keeper",
            },
            {
              label: "verse",
              text:
                "Fragments of yesterday's doings\n" +
                "It all seems so unorganized\n" +
                "Time is just a ticking bomb\n" +
                "My nerves are strained to the breaking point",
            },
            {
              label: "verse",
              text:
                "Where do we go from here\n" +
                "Walking on the edge of time\n" +
                "Where do we go from here\n" +
                "Walking on the edge of time\n" +
                "I'm the time keeper\n" +
                "I'm the time keeper",
            },
          ],
        },
      ],
      publisher: "Cope Records",
      roles: ["composer", "vocals", "percussion", "producer"],
      img: "/the-last-night.jpg",
      id: "the-last-night-2004",
    },
    {
      title: "Drós",
      description: "",
      year: 2002,
      artist: "dros",
      type: "album",
      listenOn: {
        spotify:
          "https://open.spotify.com/album/5KY3jL5uwODsaeDqgXzwHp?si=0HDxWM_RSayuUBA0EYYJKg",
        appleMusic: "https://itunes.apple.com/us/album/dr%C3%B3s/736352192",
      },
      songs: [
        { title: "Rudolf", length: "1:40", id: "dros-rudolf" },
        {
          title: "Nú glampa stjørnur himni á",
          length: "2:34",
          id: "dros-nu-glampa-stjoernur-himni-a",
        },
        {
          title: "Jól eru nær",
          length: "4:36",
          id: "dros-jol-eru-naer",
        },
        {
          title: "Tað er ti tað er jól i dag",
          length: "3:56",
          id: "dros-tad-er-ti-tad-er-jol-i-dag",
        },
        {
          title: "Tit truføstu komið",
          length: "2:39",
          id: "dros-tit-trufoestu-komid",
        },
        {
          title: "Jól í Føroyum",
          length: "3:44",
          id: "dros-jol-i-foeroyum",
        },
        {
          title: "Sæla nátt",
          length: "5:51",
          id: "dros-saela-natt",
        },
        {
          title: "Heilaga stund",
          length: "3:42",
          id: "dros-heilaga-stund",
        },
        {
          title: "Hvi man tað vera so hugnaligt",
          length: "3:15",
          id: "dros-hvi-man-tad-vera-so-hugnaligt",
        },
      ],
      publisher: "Tutl Records",
      roles: ["composer", "vocals", "percussion", "piano", "arranger"],
      img: "/dros.jpg",
      id: "dros-2002",
      isNotPersonalProject: true,
    },
    {
      title: "Where It All Begins",
      description: "First album by Maria Guttesen.",
      year: 1994,
      artist: "Maria Guttesen",
      type: "album",
      listenOn: {
        spotify:
          "https://open.spotify.com/album/6d9It1MAaIjH77QEPxbvxJ?si=nTgqTATnRe-sUg3_5q94cg",
        appleMusic:
          "https://itunes.apple.com/dk/album/where-it-all-begins/724763245",
      },
      songs: [
        {
          title: "Where It All Begins",
          length: "5:12",
          id: "where-it-all-begins-where-it-all-begins",
        },
        {
          title: "Come Walk with Me",
          length: "5:53",
          id: "where-it-all-begins-come-walk-with-me",
        },
        {
          title: "The Chain Around My Heart",
          length: "4:59",
          id: "where-it-all-begins-the-chain-around-my-heart",
          lyricsBy: "Maria Salomon",
          musicBy: "Maria Guttesen & Regin Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "You will always be my life's companion\n" +
                "Follow me like a shadow\n" +
                "Trace me where ever I go\n" +
                "Make me cross my limits \n" +
                "Leave me no rest",
            },
            {
              label: "verse",
              text:
                "You are the chain\n" +
                "around my heart\n" +
                "Lies hidden behind the words\n" +
                "You are the chain \n" +
                "around my heart\n" +
                "You come out as a winner",
            },
            {
              label: "verse",
              text:
                "You will always be the undefeated\n" +
                "I can feel your appearance\n" +
                "When I reach the ground I know\n" +
                "You will draw the curtains\n" +
                "Just for a while",
            },
            {
              label: "verse",
              text:
                "You are the chain\n" +
                "around my heart\n" +
                "Lies hidden behind the words\n" +
                "You are the chain \n" +
                "around my heart\n" +
                "You come out as a winner",
            },
            {
              label: "verse",
              text:
                "I will take, the long way\n" +
                "Keep on trying, I will carry on",
            },
            {
              label: "verse",
              text:
                "You are the chain\n" +
                "around my heart\n" +
                "Lies hidden behind the words\n" +
                "You are the chain \n" +
                "around my heart\n" +
                "You come out as a winner",
            },
          ],
        },
        {
          title: "Too Much Talking",
          length: "4:36",
          id: "where-it-all-begins-too-much-talking",
        },
        {
          title: "It's You That I Want",
          length: "4:58",
          id: "where-it-all-begins-its-you-that-i-want",
        },
        {
          title: "I'm a New Girl",
          length: "5:04",
          id: "where-it-all-begins-im-a-new-girl",
        },
        {
          title: "Divided Minds",
          length: "6:19",
          id: "where-it-all-begins-divided-minds",
          lyricsBy: "Maria Salomon",
          musicBy: "Maria Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "I've been to long\n" +
                "on lonely streets\n" +
                "seen so much misery\n" +
                "enough sorrow and fear\n" +
                "reflects my mind - uhh\n" +
                "my mind",
            },
            {
              label: "verse",
              text:
                "We can't guide \n" +
                "our problems into\n" +
                "into something unreal\n" +
                "we can't hide\n" +
                "hide in a world\n" +
                "that's trembling",
            },
            {
              label: "verse",
              text:
                "Divided Minds\n" +
                "in restless bodies\n" +
                "just small people\n" +
                "in a lonely world\n" +
                "just small people \n" +
                "in a lonely world",
            },
            {
              label: "verse",
              text:
                "I know where\n" +
                "I can preserve my\n" +
                "my agony my faults\n" +
                "I do know\n" +
                "where to search\n" +
                "shift and task\n" +
                "shift and task",
            },
            {
              label: "verse",
              text:
                "Divided Minds\n" +
                "in restless bodies\n" +
                "just small people\n" +
                "in a lonely world\n" +
                "just small people \n" +
                "in a lonely world",
            },
            {
              label: "verse",
              text:
                "Divided Minds\n" +
                "in restless bodies\n" +
                "just small people\n" +
                "in a lonely world\n" +
                "just small people \n" +
                "in a lonely world",
            },
          ],
        },
        {
          title: "Password Paradise",
          length: "4:43",
          id: "where-it-all-begins-password-paradise",
        },
        {
          title: "Dreams",
          length: "4:30",
          id: "where-it-all-begins-dreams",
          lyricsBy: "Maria Guttesen",
          musicBy: "Regin Guttesen",
          lyrics: [
            {
              label: "verse",
              text:
                "I have the dream of the night\n" +
                "it isn't something you just forget\n" +
                "Tell me why\n" +
                "Tell my why it happens",
            },
            {
              label: "verse",
              text:
                "Wake up the next day\n" +
                "The heart beats very fast\n" +
                "Tell me why\n" +
                "Tell me why it happens",
            },
            {
              label: "verse",
              text:
                "The dream is over now\n" +
                "My pillow is wet from tears\n" +
                "Something broke my soul\n" +
                "And the dream is over now",
            },
            {
              label: "verse",
              text:
                "Just second in a lifetime\n" +
                "Shakes my shakes my mind\n" +
                "Tell me why\n" +
                "Tell me why it happens",
            },
            {
              label: "verse",
              text:
                "Wake up the next day\n" +
                "The heart bets very fast\n" +
                "Tell me why\n" +
                "Tell me why it happens",
            },
            {
              label: "verse",
              text:
                "The dream is over now\n" +
                "My pillow is wet from tears\n" +
                "Something broke my soul\n" +
                "And the dream is over now",
            },
            {
              label: "verse",
              text:
                "//: Below the blue sky\n" +
                "There were dark clouds\n" +
                "Was it true\n" +
                "What I ://\n" +
                "Saw",
            },
            {
              label: "verse",
              text:
                "Tell me why \n" +
                "Tell me why \n" +
                "Tell me why\n" +
                "Tell me why",
            },
          ],
        },
      ],
      publisher: "Tutl Records",
      roles: ["composer", "vocals", "producer", "lyricist"],
      img: "/where-it-all-begins.jpg",
      id: "where-it-all-begins-1994",
    },
    {
      title: "Barnasangir",
      description: "",
      year: 1979,
      artist: "Hjørdis og genturnar",
      type: "album",
      listenOn: {
        spotify: "https://open.spotify.com/album/4rmGCK6ypXe3Wj63SQNqsW",
        appleMusic: "https://itunes.apple.com/us/album/barnasangir/767586959",
      },
      songs: [
        {
          title: "Bimm Bamm Bussa",
          length: "0:54",
          id: "barnasangir-bimm-bamm-bussa",
        },
        {
          title: "Eitt Lítið Skýggj",
          length: "1:11",
          id: "barnasangir-eitt-litid-skyggj",
        },
        {
          title: "Sólin",
          length: "0:34",
          id: "barnasangir-solin",
        },
        {
          title: "Nikolaj",
          length: "0:36",
          id: "barnasangir-nikolaj",
        },
        {
          title: "Á Sum Eg Eri Glað Í Dag",
          length: "0:29",
          id: "barnasangir-a-sum-eg-eri-glad-i-dag",
        },
        {
          title: "Dukkan Fía",
          length: "1:27",
          id: "barnasangir-dukkan-fia",
        },
        {
          title: "Í Reiðri Kúrin Bøgan Lá",
          length: "0:54",
          id: "barnasangir-i-reidri-kurin-boegan-la",
        },
        {
          title: "Sig Mær, Sást Tú Tað",
          length: "0:44",
          id: "barnasangir-sig-maer-sast-tu-tad",
        },
        {
          title: "Rivnar Gularøtur",
          length: "0:52",
          id: "barnasangir-rivnar-gularoetur",
        },
        {
          title: "Eg Veit Ein Lítlan Barnagarð",
          length: "0:48",
          id: "barnasangir-eg-veit-ein-litlan-barnagard",
        },
        {
          title: "Í Barnagarði Ynski Eg",
          length: "3:35",
          id: "barnasangir-i-barnagardi-ynski-eg",
        },
        {
          title: "Indianarabørnini",
          length: "1:12",
          id: "barnasangir-indianaraboernini",
        },
        {
          title: "Eg Vil Telja Stjørnurnar",
          length: "1:14",
          id: "barnasangir-eg-vil-telja-stjoernurnar",
        },
        {
          title: "So Klappa Vit",
          length: "1:13",
          id: "barnasangir-so-klappa-vit",
        },
        {
          title: "Allir Kannubjøllumenn",
          length: "0:53",
          id: "barnasangir-allir-kannubjoellumenn",
        },
        {
          title: "Vestan Fyri Mykines",
          length: "0:57",
          id: "barnasangir-vestan-fyri-mykines",
        },
        {
          title: "Mala Hendaveg",
          length: "0:55",
          id: "barnasangir-mala-hendaveg",
        },
        {
          title: "Tú Skalt Klappa",
          length: "1:19",
          id: "barnasangir-tu-skalt-klappa",
        },
        {
          title: "Veðrið Verður Lýggjari",
          length: "1:50",
          id: "barnasangir-vedrid-verdur-lyggjari",
        },
        {
          title: "Eg Veit Eitt Spógvareiður",
          length: "1:25",
          id: "barnasangir-eg-veit-eitt-spogvareidur",
        },
        {
          title: "Fingulingurin",
          length: "0:54",
          id: "barnasangir-fingulingurin",
        },
        {
          title: "Tyggigummikongur Jótur",
          length: "1:16",
          id: "barnasangir-tyggigummikongur-jotur",
        },
        {
          title: "Líttla Fitta Nissa Mín",
          length: "0:40",
          id: "barnasangir-litla-fitta-nissa-min",
        },
        {
          title: "Dukka Mín Er Blá",
          length: "0:45",
          id: "barnasangir-dukka-min-er-bla",
        },
        {
          title: "Hygg, Kavin Hann Er Komin",
          length: "0:50",
          id: "barnasangir-hygg-kavin-hann-er-komin",
        },
        {
          title: "Í Dag Er So Ljóst",
          length: "1:54",
          id: "barnasangir-i-dag-er-so-ljost",
        },
        {
          title: "Sov Mín Søti Skattur",
          length: "1:20",
          id: "barnasangir-sov-min-soeti-skattur",
        },
      ],
      publisher: "Tutl Records",
      roles: ["vocals"],
      img: "/barnasangir.jpg",
      id: "barnasangir-1979",
      isNotPersonalProject: true,
    },
  ],
};
