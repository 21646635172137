import styled from "styled-components";

const Heading = styled.h2`
  font-size: 30px;
  text-transform: uppercase;
  font-family: "Gaegu", sans-serif;
  margin: 0;
`;

export default Heading;
