const theme = {
  fontSizes: {
    mini: "8px",
    xsmall: "11px",
    small: "14px",
    medium: "18px",
    large: "24px",
    xlarge: "32px",
    huge: "43px",
    xhuge: "57px",
    giant: "76px",
  },
};

export default theme;
