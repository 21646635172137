import styled from "styled-components";
import theme from "../theme";

const PageTitle = styled.h2`
  &&& {
    display: block;
    color: #444;
    font-size: ${theme.fontSizes.huge};
    font-weight: bold;
    text-transform: uppercase;
    font-family: "Gaegu", sans-serif;
    margin: 0;

    @media (max-width: 650px) {
      font-size: ${theme.fontSizes.xlarge};
    }
  }
`;

export default PageTitle;
