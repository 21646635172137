import React from "react";
import { useParams } from "react-router-dom";
import withScrollToTopWhenMounted from "../components/withScrollToTopWhenMounted";
import { data } from "../data.js";
import Lyrics from "../components/Lyrics";

const findSongById = (id) => {
  let songs = [];
  for (let project of data.projects) {
    songs = [...songs, ...project.songs];
  }
  const matches = songs.filter((song) => song.id === id);
  if (matches.length > 0) return matches[0];
  else return null;
};

const LyricsView = () => {
  const { songId } = useParams();
  const song = findSongById(songId);
  if (song === null) {
    return <p>Could not find song with ID "{songId}"</p>;
  }
  return <Lyrics song={song} />;
};

export default withScrollToTopWhenMounted(LyricsView);
