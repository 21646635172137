import React from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { data } from "../data.js";
import Project from "../components/Project";
import VerticalSpace from "../components/VerticalSpace";
import HorizontalSpace from "../components/HorizontalSpace";
import ShowAllButton from "../components/ShowAllButton";
import ProjectList from "../components/ProjectList";
import Heading from "../components/Heading";

const projectsWithType = (type) =>
  data.projects
    .filter((project) => project.type === type)
    .map((project) => (
      <Project
        key={Date.now() + project.title}
        metadata={project}
        highlightType={type}
      />
    ));

const TypeName = styled.span`
  color: ${({ theme }) => theme.highlight};
  text-transform: capitalize;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const TypeView = () => {
  const { typeName } = useParams();
  const navigate = useNavigate();

  return (
    <div>
      <Row>
        <Heading>
          Project type: <TypeName>{typeName}</TypeName>
        </Heading>
        <HorizontalSpace $amount={10} />
        <ShowAllButton type="button" onClick={() => navigate("/")} />
      </Row>
      <VerticalSpace $amount={20} />
      <ProjectList>{projectsWithType(typeName)}</ProjectList>
    </div>
  );
};

export default TypeView;
