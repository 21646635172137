import React from "react";
import styled from "styled-components";

import PageTitle from "../components/PageTitle";
import withScrollToTopWhenMounted from "../components/withScrollToTopWhenMounted";

const PageContainer = styled.div`
  max-width: 600px;
`;

const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25;
  height: 0;
  margin-top: 50px;
`;

const VideoIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const VideosView = () => {
  return (
    <PageContainer>
      <PageTitle>Videos</PageTitle>
      <VideoContainer>
        <VideoIframe
          src="https://www.youtube.com/embed/XBqDlFaa_DQ"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </VideoContainer>
      <VideoContainer>
        <VideoIframe
          src="https://www.youtube.com/embed/_nVVf5N6ylM"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </VideoContainer>
      <VideoContainer>
        <VideoIframe
          src="https://www.youtube.com/embed/tPLWKZ_t5Qw"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </VideoContainer>
    </PageContainer>
  );
};

export default withScrollToTopWhenMounted(VideosView);
