import styled from "styled-components";

const ShowAllButton = styled.button`
    outline: 0;
    border: 1px solid transparent;
    background-color: yellow;
    cursor: pointer;
    text-transform: uppercase;
    padding: 1px 3px;
    font-size: 10px;
    height: 20px;
    border-radius: 5px;
    &:before {
        content: "Show all projects";
    }
    
    transition: all 300ms ease;
    
    &:hover {
        text-decoration: underline;
    }
`;

export default ShowAllButton;
