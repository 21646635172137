import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import HorizontalSpace from "./HorizontalSpace";
import SubtleMetadata from "./SubtleMetadata";

const SubtleSongMetadata = styled(SubtleMetadata)`
  font-size: 20px;
`;

const SongTitle = styled.span``;

const LyricsLabel = styled.span`
  color: ${({ theme }) => theme.primary};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const SongContainer = styled.div`
  display: inline-flex;
  align-items: center;
  color: #000;
  font-size: 20px;
  padding: 5px 0;
`;

const Song = ({ metadata }) => {
  const navigate = useNavigate();

  return (
    <SongContainer>
      <SongTitle>{metadata.title}</SongTitle>
      {metadata.lyrics && (
        <React.Fragment>
          <HorizontalSpace $amount={7.5} />
          <SubtleSongMetadata>{"·"}</SubtleSongMetadata>
          <HorizontalSpace $amount={7.5} />
          <LyricsLabel onClick={() => navigate(`/song/${metadata.id}/lyrics`)}>
            Lyrics »
          </LyricsLabel>
        </React.Fragment>
      )}
    </SongContainer>
  );
};

export default Song;
