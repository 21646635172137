import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "./themes";

ReactDOM.createRoot(document.getElementById("root")).render(
  <BrowserRouter>
    <ThemeProvider theme={defaultTheme}>
      <App />
    </ThemeProvider>
  </BrowserRouter>
);
registerServiceWorker();
